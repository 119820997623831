import React from "react";
import styles from './OrdersCVVList.module.scss';
import {getPictureSrc} from "../../../store/selectors";

const OrdersCVVList = ({historyList, setChangedToken, ticker = false}) => {
    const pictureSrc = getPictureSrc;

    return (
        <ul className={styles.ordersCVVList}>
            {historyList && historyList.length && (
                historyList.map((item, index) => (
                    <li key={index} className='switch' onClick={() => setChangedToken(ticker ? item.ticker : item.name)}>
                        <div className={styles.ordersCVVList__header}>
                            <img src={`${pictureSrc}${item.icon}`} alt=""/>
                            <p>{ticker ? item.ticker : item.name}</p>
                        </div>
                        <span className='circle' />
                        <input type="radio" name='ordersCVV' />
                    </li>
                ))
            )}
        </ul>
    )
}

export default OrdersCVVList;