import React from "react";
import styles from './ExchangeExecuted.module.scss';
import backgroundDark from '../../../../assets/icons/bgDarkSecond.png';
import backgroundLight from '../../../../assets/icons/bgLightSecond.png';
import {useSelector} from "react-redux";
import {getTheme} from "../../../../store/selectors";
import {SecondHeader} from "../../../Common/SecondHeader/SecondHeader";
import ExecutedCurrency from "./ExecutedCurrency/ExecutedCurrency";
import ExecutedProcess from "./ExecutedProcess/ExecutedProcess";
import ExecutedCommission from "./ExecutedCommission/ExecutedCommission";

const ExchangeExecuted = ({giveValue, getValue, text, showExchange, setShowExchange, giveCurrency, getCurrency, commission}) => {
    const theme = useSelector(getTheme);

    return (
        <div className={showExchange ? styles.exchangeExecutedActive : styles.exchangeExecuted}
             style={theme === 'dark' ? {backgroundImage: `url(${backgroundDark})`} : {backgroundImage: `url(${backgroundLight})`}}>
            <SecondHeader setShowExchange={setShowExchange} text={text.warrantExecuted}/>

            <div className={`${styles.exchangeExecuted__content} bgMain`}>
                <ExecutedCurrency
                    giveValue={giveValue}
                    getValue={getValue}
                    giveCurrency={giveCurrency}
                    getCurrency={getCurrency}/>
                <ExecutedProcess textDay={text.day} textStatus={text.status} textToday={text.today}/>
                <ExecutedCommission text={text.commission} commission={commission} />
            </div>
        </div>
    )
}

export default ExchangeExecuted;