import React, {useState} from "react";
import styles from './OrderItem.module.scss';
import remove from "../../../../../assets/icons/trash.svg";
import removeWhite from "../../../../../assets/icons/trashWhite.svg";
import OrderContentValue from "./OrerContentValue/OrderContentValue";
import {useAppDispatch} from "../../../../../hooks/redux";
import {removeOrder, setFavorite} from "../../../../../store/pro-slice";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTheme, getUserId} from "../../../../../store/selectors";
import {refreshAccessToken, removeOpenOrders} from "../../../../../api/api";
import {setAcToken} from "../../../../../store/account-slice";

const OrderItem = ({item, setIsLoading}) => {
    const theme = useSelector(getTheme);
    const dispatch = useAppDispatch();
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const [showConfirm, setShowConfirm] = useState(false);

    const onRemove = (id) => {
        setIsLoading(true);
        if (id && telegramId && accessToken) {
            removeOpenOrders(id, telegramId, accessToken).then(r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            removeOpenOrders(id, telegramId, r.access_token).then(r => {
                                if (r && r.id) {
                                    dispatch(removeOrder(r.id));
                                    setIsLoading(false);
                                    setShowConfirm(false);
                                }
                            })
                        }
                    });
                }
                if (r && r.id) {
                    dispatch(removeOrder(r.id));
                    setIsLoading(false);
                    setShowConfirm(false);
                } else {
                    setIsLoading(false);
                }
            })
        } else {
            setIsLoading(false);
            setShowConfirm(false)
        }
    }

    return (
        <li className={`${styles.orderItem} `}>
            <div className={styles.orderItem__header}>
                <div className={styles.orderItem__headerCurrency}>
                    <p>{item.name}</p>
                    {item.order === "limit" && (
                        <span
                            className={item.side === 'BUY' ? styles.limitBuy : styles.limitSell}>
                            {item.side === 'BUY' ? 'Buy' : 'Sell'}
                        </span>
                    )}
                </div>
                <div className={styles.orderItem__headerData}>
                    <span>{item.date}</span>
                    <span>{item.time}</span>
                </div>
            </div>
            <span className='decoration' />
            <div className={styles.orderItem__content}>
                <OrderContentValue amount={item.amount} side={item.side} order={item.order}
                                   price={item.price} partially={item.partially} />
                <button onClick={() => setShowConfirm(true)} className={`${styles.delete} bgLittleBtn`}>
                    <img src={theme === 'dark' ? remove : removeWhite} alt=""/>
                </button>
            </div>
            {showConfirm && (
                <span className={styles.shadow}/>
            )}
            <div className={showConfirm ? `${styles.popupActive} bgSecond` : `${styles.popup} bgSecond`}>
                <p>Are you sure to delete the order?</p>
                <div className={styles.popup__actions}>
                    <button className={styles.btnDelete} onClick={() => onRemove(item.order_id)}>
                        <span>Delete</span>
                    </button>
                    <button className={styles.btnCancel} onClick={() => setShowConfirm(false)}>
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </li>
    )
}

export default OrderItem;