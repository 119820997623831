import React, {useEffect, useState} from "react";
import styles from './VerificationOptions.module.scss';
import {useSelector} from "react-redux";
import {getIsTwoFactor, getText} from "../../../store/selectors";
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import {useNavigate} from "react-router";

const VerificationOptions = () => {
    const navigate = useNavigate();
    const isTwoFactorAuth = useSelector(getIsTwoFactor);
    const {account} = useSelector(getText);
    const [isTelegramBot, setIsTelegramBot] = useState(false);
    const [isGoggleAuth, setIsGoogleAuth] = useState(false);

    useEffect(() => {
        setIsGoogleAuth(isTwoFactorAuth);
    }, [isTwoFactorAuth])

    const onGoogleAuthChange = () => {
        setIsGoogleAuth(!isGoggleAuth);

        setTimeout(() => {
            if (isGoggleAuth) {
                navigate('/account/verificationGoogleOff/')
            }
        }, 300)

    }

    return (
        <div className={`${styles.verificationOptions} bgMain`}>
            <PagesHeader headerText={account.verification} />
            <div className={styles.options}>
                <div className={`${styles.option} bgBlock`}>
                    <div className={styles.option__info}>
                        <p className={styles.option__title}>Telegram bot</p>
                        <p className={styles.option__text}>{account.telegramBot}</p>
                    </div>
                    <button className={isTelegramBot ? `${styles.switcherBoxActive} linkActive` : styles.switcherBox}
                            onClick={() => setIsTelegramBot(!isTelegramBot)}>
                        <span className={isTelegramBot ? styles.switcherTrue : styles.switcherFalse} />
                    </button>
                </div>
                <div className={`${styles.option} bgBlock`}>
                    <div className={styles.option__info}>
                        <p className={styles.option__title}>Google Authenticator</p>
                        <p className={styles.option__text}>{account.googleAuth}</p>
                    </div>
                    <div className={styles.option__switcher}>
                        <button className={isGoggleAuth ? `${styles.switcherBoxActive} linkActive` : styles.switcherBox}
                                onClick={() => onGoogleAuthChange()}>
                            <span className={isGoggleAuth ? styles.switcherTrue : styles.switcherFalse} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerificationOptions;