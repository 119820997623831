import React from "react";
import styles from './ExchangeChoose.module.scss';
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

const ExchangeChoose = ({swapText, spotText}) => {
    const location = useLocation().pathname;

    return (
        <div className={styles.exchangeChoose}>
            <div className={styles.exchangeChoose__button}>
                <Link className={location === '/pro/spot/'
                    ? `${styles.linkActive} ${styles.active} linkActive bgBlock`
                    : `${styles.link} bgBlock`}
                      to='/pro/spot/'>{spotText}
                </Link>
            </div>
            <div className={styles.exchangeChoose__button}>
                <Link className={location === '/pro/exchange/'
                    ? `${styles.linkActive} ${styles.active} linkActive bgBlock`
                    : `${styles.link} bgBlock`}
                      to='/pro/exchange/'>{swapText}
                </Link>
            </div>
        </div>
    )
}

export default ExchangeChoose;