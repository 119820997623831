import React, {useState} from "react";
import styles from './ExchangeMainButton.module.scss';
import {getAllTickers, refreshAccessToken, SendExchange, sendLimitOrder} from "../../../../api/api";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getUserId} from "../../../../store/selectors";
import {useAppDispatch} from "../../../../hooks/redux";
import {setAcToken} from "../../../../store/account-slice";
import {setEventPopup} from "../../../../store/main-slice";

const ExchangeMainButton = ({value, currentTicker, operation, pro, priceChoose, coursePrice, updateDates, setIsLoading, setCoursePrice, setProcess, setTotal, setValue, updateOpenOrders}) => {
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [isDisabled, setIsDisabled] = useState(false);


    const onSubmit = () => {
        if (telegramId && accessToken && coursePrice > 0) {
            setIsDisabled(true);
            setIsLoading(true);
            setTimeout(() => {
                setIsDisabled(false);
            }, 5000);

            priceChoose === 'market'
                ? SendExchange(operation.toUpperCase(), currentTicker.ticker, value, telegramId, accessToken).then(r => {
                    if (r && r.detail && r.detail === 'access_token expired') {
                        refreshAccessToken(refreshToken).then(r => {
                            if (r && r.access_token) {
                                dispatch(setAcToken(r.access_token));
                                SendExchange(operation.toUpperCase(), currentTicker.ticker, value, telegramId, r.access_token).then(r => {
                                    if (r && r.orderId) {
                                        dispatch(setEventPopup({
                                            success: true,
                                            text: 'Exchange successfully!'
                                        }))
                                        setIsLoading(false);
                                        setCoursePrice(0);
                                        setProcess(0);
                                        setTotal(0);
                                        setValue(0);
                                        updateDates();
                                    }
                                })
                            }
                        });
                    }
                    if (r && r.orderId) {
                        setIsLoading(false);
                        dispatch(setEventPopup({
                            success: true,
                            text: 'Exchange successfully!'
                        }))
                        updateOpenOrders();
                        setCoursePrice(0);
                        setProcess(0);
                        setTotal(0);
                        setValue(0);
                        updateDates();
                    }
                })
                : sendLimitOrder(operation.toUpperCase(), currentTicker.ticker, value, telegramId, coursePrice, accessToken).then(r => {
                    if (r && r.detail && r.detail === 'access_token expired') {
                        refreshAccessToken(refreshToken).then(r => {
                            if (r && r.access_token) {
                                dispatch(setAcToken(r.access_token));
                                sendLimitOrder(operation.toUpperCase(), currentTicker.ticker, value, telegramId, coursePrice, r.access_token).then(r => {
                                    if (r && r.id) {
                                        setIsLoading(false);
                                        dispatch(setEventPopup({
                                            success: true,
                                            text: 'Order created!'
                                        }))
                                        setCoursePrice(0);
                                        setProcess(0);
                                        setTotal(0);
                                        setValue(0);
                                        updateDates();
                                        updateOpenOrders();
                                    } else {
                                        setIsLoading(false);
                                        dispatch(setEventPopup({
                                            success: false,
                                            text: 'Order failed!'
                                        }))

                                    }
                                })
                            }
                        });
                    }
                    if (r && r.id) {
                        setIsLoading(false);
                        dispatch(setEventPopup({
                            success: true,
                            text: 'Order created!'
                        }))
                        setCoursePrice(0);
                        setProcess(0);
                        setTotal(0);
                        setValue(0);
                        updateDates();
                        updateOpenOrders();
                    } else {
                        setIsLoading(false);
                        dispatch(setEventPopup({
                            success: false,
                            text: 'Order failed!'
                        }))
                    }
                })
        }
    }


    return (
        <div className={`${styles.exchangeMainButton} contentText`}>
            {/*<p className={styles.text}>{operation} ~ 650.14363975 USDT for 593.3800 EUR <br/> at Market Price of ~ 0.9127 EUR</p>*/}
            <button className={operation === 'Buy' ? styles.buyBtn : styles.sellBtn}
                    disabled={value < 0.0000000000000001 || !currentTicker || isDisabled} onClick={() => onSubmit()}>
                <p>
                    <span>{operation === 'Buy' ? pro.buy : pro.sell}</span>

                    {currentTicker && currentTicker.ticker && (
                        <span>{currentTicker.ticker}</span>
                    )}
                </p>

                {currentTicker && currentTicker.token && (
                    <p>{operation === 'Buy' ? 'Get' : 'Give'} =
                        100 {currentTicker.token} {operation === 'Buy' ? 'give' : 'get'} {(100 * coursePrice).toFixed(5)} {currentTicker.token2}</p>
                )}
            </button>

        </div>
    )
}

export default ExchangeMainButton;