import {createSlice} from "@reduxjs/toolkit";
import usdtIcon from "../assets/currencyIcons/usdt.svg";
import bnbIcon from "../assets/currencyIcons/bnb.svg";
import apexIcon from "../assets/currencyIcons/apex.svg";
import ltcIcon from "../assets/currencyIcons/ltc.svg";
import btcIcon from "../assets/currencyIcons/btc.svg";
import ethIcon from "../assets/currencyIcons/eth.svg";
import trxIcon from "../assets/currencyIcons/trx.svg";

const initialState = {
    wallets: [],
    networkWallets: [],
    currentToken: {
        network: '',
        token: {},
    },
    currentNetwork: null,
    currentCurrency: null,
};

export const walletSlice = createSlice({
    name: 'walletPage',
    initialState,
    reducers: {
        setCurrentToken(state, {payload}) {
            state.currentToken = {
                network: payload.network,
                token: payload.item
            }
        },
        setCurrentNetwork(state, {payload}) {
            state.currentNetwork = payload;
        },
        setCurrentCurrency(state, {payload}) {
            state.currentCurrency = payload;
        },
        setNewWallet(state, {payload}) {
            state.wallets = state.wallets.map((item) => {
                if (item.id === payload.id) {
                    item.innerWallets = [...item.innerWallets, payload.newWallet]
                }
                return item;
            })
        },
        setUserWallets(state, {payload}) {
            state.wallets = payload
        },
        setNetworkWallets(state, {payload}) {
            state.networkWallets = payload;
        }
    }
})

export default walletSlice.reducer;

export const {
    setCurrentToken,
    setNewWallet,
    setUserWallets,
    setNetworkWallets,
    setCurrentNetwork,
    setCurrentCurrency
} = walletSlice.actions;
