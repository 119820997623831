import React from "react";
import styles from './ExecutedCommission.module.scss';

const ExecutedCommission = ({text, commission}) => {
    return (
        <div className={`${styles.executedCommission} bgBlock`}>
            <p>{text}</p>
            {commission && (
                <p>{commission}</p>
            )}
        </div>
    )
}

export default ExecutedCommission;