import React from "react";
import {useNavigate} from "react-router";
import backIcon from '../../../assets/icons/back.svg'
import backIconBlue from '../../../assets/icons/backBlue.svg'
import {useSelector} from "react-redux";
import {getTheme} from "../../../store/selectors";

export const Back = () => {
    const theme = useSelector(getTheme);
    const navigate = useNavigate();

    return (
        <button onClick={() => navigate(-1)}>
            <img src={theme === 'dark' ? backIcon : backIconBlue} alt=""/>
        </button>
    )
}