import React from "react";
import styles from './WalletOperations.module.scss';
import {Link} from "react-router-dom";

const WalletOperations = ({deposit, withdraw, exchange}) => {
    return (
        <ul className={styles.walletOperations}>
            <li><Link to={'/wallet/deposit/'}><span>{deposit}</span></Link></li>
            <li><Link to={'/wallet/withdraw/'}><span>{withdraw}</span></Link></li>
            <li><Link to={'/pro/exchange/'}><span>{exchange}</span></Link></li>
        </ul>
    )
}

export default WalletOperations;