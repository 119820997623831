import React from "react";
import styles from './NetworkList.module.scss';
import {getText} from "../../../../store/selectors";
import {useSelector} from "react-redux";

const NetworkList = ({networks, setNetwork}) => {
    const {wallet} = useSelector(getText);

    return (
        <div className={`${styles.networksList} bgBlock`}>
            <p className={styles.networksList__label}>{wallet.selectNetwork}</p>
            <ul className={styles.networksList__list}>
                {networks && (
                    networks.map((item, index) => (
                        <li key={index} className='bgListItem switch'>
                            <input type="radio" name='networkWallet' onChange={() => setNetwork(item.networkType)}/>
                            <div className={styles.networkChange}>
                                <span>{item.networkType}</span>
                                <span className='circle'/>
                            </div>
                        </li>
                    ))
                )}
            </ul>
        </div>
    )
}

export default NetworkList;