import React from "react";
import styles from './OrderContentValue.module.scss';

const OrderContentValue = ({order, side, amount, partially, price}) => {
    return (
        <div className={styles.contentValue}>
            <div>
                <p className={styles.orderItem__label}>
                    {order === 'limit' ? 'Amount' : 'Side'}
                </p>
                <p className={order === 'market' && side === 'BUY' ? styles.limitBuy
                    : order === 'market' && side === 'Sell' ? styles.limitSell
                        : styles.orderItem__value}
                >
                    {order === 'limit' ? `${amount}` : side === 'BUY' ? 'Buy' : 'Sell'}
                </p>
            </div>
            <div>
                <p className={styles.orderItem__label}>
                    {order === 'limit' ? 'Price' : 'Amount'}
                </p>
                <p className={styles.orderItem__value}>
                    {order === 'limit' ? `${price}` : `${amount}`}
                </p>
            </div>
            <div>
                <p className={order === 'limit' ? styles.orderItem__labelLimit : styles.orderItem__label}>
                    {order === 'limit' ? 'Partially completed' : 'Orders price'}
                </p>
                <p className={styles.orderItem__value}>
                    {order === 'limit' ? `${partially}` : `${price}`}
                </p>
            </div>
        </div>
    )
}

export default OrderContentValue;