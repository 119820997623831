import React from "react";
import styles from "../CouplesList.module.scss";
import favoriteIconActive from "../../../../../../assets/icons/favoriteItemActive.svg";
import favoriteIcon from "../../../../../../assets/icons/favoriteItem.svg";
import favoriteIconBlueActive from "../../../../../../assets/icons/favoriteItemBlueActive.svg";
import favoriteIconBlue from "../../../../../../assets/icons/favoriteItemBlue.svg";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTheme, getUserId} from "../../../../../../store/selectors";
import {useAppDispatch} from "../../../../../../hooks/redux";
import {setFavorite} from "../../../../../../store/pro-slice";
import {generateWalletAddress, refreshAccessToken, setChangeFavorite} from "../../../../../../api/api";
import {setAcToken} from "../../../../../../store/account-slice";


const CouplesItem = ({item, setCurrentTicker, setShowFilters, setProcess, setSellValue}) => {
    const theme = useSelector(getTheme);
    const dispatch = useAppDispatch();
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);

    const addToFavorite = (event, itemId) => {
        event.stopPropagation();
        if (telegramId && itemId && accessToken) {
            setChangeFavorite(telegramId, itemId, accessToken).then(r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            setChangeFavorite(telegramId, itemId, r.access_token).then(r => {
                                if (r && r.result) {
                                    dispatch(setFavorite(itemId));
                                }
                            })
                        }
                    });
                } else if (r && r.result) {
                    dispatch(setFavorite(itemId));
                }
            })
        }
    }

    const setTicker = () => {
        setSellValue(0);
        setProcess(0);
        setCurrentTicker(item);
        setShowFilters(false);
    }


    return (
        <li className={`${styles.couplesList__item} bgBlock`} onClick={() => setTicker()}>
            <div className={styles.couplesList__info}>
                {/*<button onClick={(e) => addToFavorite(e, item.id)}>*/}
                <button onClick={(e) => addToFavorite(e, item.ticker_id)}>
                    <img  src={theme === 'dark' ? item.isFavorite ? favoriteIconActive : favoriteIcon
                        : item.isFavorite ? favoriteIconBlueActive : favoriteIconBlue} alt=""/>
                </button>
                <p>{item.ticker}</p>
            </div>
            <div className={styles.couplesList__value}>
                <p>{item.price}</p>
                <p>{item.token}</p>
            </div>
        </li>
    )
}

export default CouplesItem;