import React from "react";
import styles from './OrderItemsList.module.scss';
import {useSelector} from "react-redux";
import {getOrders} from "../../../../store/selectors";
import OrderItem from "./OrderItem/OrderItem";

const OrderItemsList = ({orderList, setIsLoading}) => {


    return (
        <ul className={styles.orderItemsList}>
            {orderList.length > 0 && (
                orderList.map((item, index) => (
                    <OrderItem key={index} item={item} setIsLoading={setIsLoading}/>
                ))
            )}
        </ul>
    )
}

export default OrderItemsList;