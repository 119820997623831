import React from "react";
import styles from '../Changer.module.scss';
import {getPictureSrc} from "../../../../../store/selectors";


const ExchangeDropdown = ({showDropdown, value, currency, setCurrency, setShowDropdown, currentCurrency, setIsLoading, operation}) => {
    const pictureSrc = getPictureSrc;

    const onChange = (item) => {
        setCurrency(item);
        setTimeout(() => {
            setShowDropdown('')
        })
    }


    return (
        <ul className={showDropdown === value ? styles.customSelect__contentActive : styles.customSelect__content}>
            {currency && (
                currency.map((item, index) => (
                    <li className={currentCurrency && currentCurrency.name === item.name ? 'action' : null} key={index} onClick={() => {
                        if (operation === 'Give') {
                            setIsLoading(true)
                        }
                        onChange(item)
                    }}>
                        <img className={styles.icon} src={`${pictureSrc}${item.icon}`} alt=""/>
                        <p>{item.name}</p>
                    </li>
                ))
            )}
        </ul>
    )
}

export default ExchangeDropdown;