import React from "react";
import styles from './WalletSearch.module.scss';
import searchIcon from '../../../assets/icons/search.svg';

const WalletSearch = ({text, hideZero, setHideZero, searchValue, setSearchValue}) => {
    return (
        <div className={styles.walletSearch}>
            <div className={styles.walletSearch__search}>
                <img src={searchIcon} alt=""/>
                <input className={styles.walletSearch__searchInput} value={searchValue}
                       onChange={(e) => setSearchValue(e.target.value)} type="search"/>
            </div>
            <div className={`${styles.walletSearch__hide} switch`}>
                <span className={`${styles.circle} circle`}/>
                <input className={styles.hideInput} type="checkbox" onChange={() => setHideZero(!hideZero)}/>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default WalletSearch;