import React from "react";
import styles from './SecondHeader.module.scss';
import backIcon from '../../../assets/icons/back.svg'

export const SecondHeader = ({text, setShowExchange}) => {

    return (
        <div className={styles.secondHeader}>
            <button onClick={() => setShowExchange(false)}>
                <img src={backIcon} alt=""/>
            </button>
            <p>{text}</p>
        </div>
    )
}