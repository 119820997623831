import {createSlice} from "@reduxjs/toolkit";
const initialState = {
    balance: 0,
    mainList: [],
    tokenList : [],
};

export const mainSlice = createSlice({
    name: 'mainPage',
    initialState,
    eventPopup: null,
    reducers: {
        setBalance(state, {payload}) {
            state.balance = payload;
        },
        setMainList(state, {payload}) {
            state.mainList = payload;
            // state.tokenList = payload.filter(item => item.token);
        },
        setEventPopup(state, {payload}) {
            state.eventPopup = payload;
        },
    }
})

export default mainSlice.reducer;
export const {
    setBalance,
    setMainList,
    setEventPopup,
} = mainSlice.actions;
