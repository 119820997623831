import React from "react";
import styles from './VerificationKyc.module.scss';
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import kycBg from '../../../assets/icons/KycBg.png'
import {useSelector} from "react-redux";
import {getText} from "../../../store/selectors";
import {useNavigate} from "react-router";

const VerificationKyc = () => {
    const {account} = useSelector(getText);
    const navigate = useNavigate();

    return (
        <div className={`${styles.verificationKyc} bgMain`} style={{backgroundImage: `url(${kycBg})`}}>
            <PagesHeader headerText={''} menu/>
            <div className={`${styles.verificationKyc__content} bgSecond`}>
                <p className={styles.verificationKyc__title}>{account.KycVerification}</p>
                <p className={styles.verificationKyc__text}>{account.KycVerificationText}</p>
                <button className={`${styles.verificationKyc__link} action`}>
                    <span>Start verification</span>
                </button>
                <button className={styles.verificationKyc__skip} onClick={() => navigate(-1)}>
                    <span>Skip</span>
                </button>
            </div>
        </div>
    )
}

export default VerificationKyc;