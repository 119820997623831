import React, {useEffect, useState} from "react";
import btn from '../../../assets/icons/btnDots.svg';
import btnBlue from '../../../assets/icons/btnDotsBlue.svg';
import styles from './WalletList.module.scss';
import {useSelector} from "react-redux";
import {getPictureSrc, getTheme, getWallets} from "../../../store/selectors";
import {Link} from "react-router-dom";

const WalletList = ({text, hideZero, setIsLoading, wallets}) => {
    const theme = useSelector(getTheme);

    const pictureSrc = getPictureSrc;
    const [list, setList] = useState([]);

    useEffect(() => {
        if (wallets.length && hideZero) {
            const newList = wallets.filter(item => item.finalBalance !== '0.000000');
            setList([]);
            setList(newList);
            setIsLoading(false);
        } else if (!hideZero && wallets.length) {
            setList([]);
            setList(wallets);
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false);
            }, 2500)
        }
    }, [wallets, hideZero])

    return (
        <ul className={styles.walletList}>
            {list && (
                list.map((item, index) => (
                    <li className={styles.walletList__item} key={index}>
                        <Link to={'/wallet/' + item.id}>
                            <div className={styles.header}>
                                <div className={styles.currency}>
                                    <img src={`${pictureSrc}${item.icon}`} alt=""/>
                                    <h2>{item.name}</h2>
                                </div>
                                <button>
                                    <img src={theme === 'dark' ? btn : btnBlue} alt=""/>
                                </button>
                            </div>
                            <div className={styles.balances}>
                                <div className={styles.balances__item}>
                                    <p>{text.commercial}</p>
                                    <span>{item.commercial}</span>
                                </div>
                                <div className={styles.balances__item}>
                                    <p>{text.warrants}</p>
                                    <span>{item.warrants}</span>
                                </div>
                                <div className={styles.balances__item}>
                                    <p>{text.finalBalanas}</p>
                                    <span>{item.finalBalance}</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))
            )}
        </ul>
    )
}

export default WalletList;