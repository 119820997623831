import styles from "./PagesHeader.module.scss";
import {Back} from "../Back/Back";
import burgerIcon from "../../../assets/icons/burgerMenu.svg";
import burgerIconBlue from "../../../assets/icons/burgerMenuBlue.svg";
import React from "react";
import {useSelector} from "react-redux";
import {getTheme} from "../../../store/selectors";
import {useNavigate} from "react-router";


export const PagesHeader = ({headerText, menu = false}) => {
    const theme = useSelector(getTheme);
    const navigate = useNavigate();

    return (
        <div>
            <div className={styles.pagesHeader}>
                <Back />
                <h2>{headerText}</h2>
                <button onClick={() => navigate('/account/')} className={menu ? null : styles.hide}>
                    <img src={theme === 'dark' ? burgerIcon : burgerIconBlue} alt=""/>
                </button>
            </div>
        </div>
    )
}