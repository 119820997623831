import React from "react";
import styles from './HistoryOrdersActionsCVV.module.scss';
import {useAppSelector} from "../../../hooks/redux";
import {getText} from "../../../store/selectors";

const HistoryOrdersActionsCVV = ({setShowOrders, onGetCVV}) => {
    const {history} = useAppSelector(getText);
    return (
        <div className={styles.historyOrdersActionsCVV}>
            <button className={`${styles.done} continueBtn`} onClick={() => setShowOrders(false)}>
                <span>{history.done}</span>
            </button>
            <button className={`cancelBtn bgBlock`} onClick={() => onGetCVV()}>
                <span>{history.getCvv}</span>
            </button>
        </div>
    )
}

export default HistoryOrdersActionsCVV;