import React, {useEffect, useState} from "react";
import styles from './ProcessRange.module.scss';
import {useSelector} from "react-redux";
import {getTheme} from "../../../../store/selectors";

const ProcessRange = ({process, setProcess, availableBalance, setValue, operation, setQuanity, total, coursePrice}) => {
    const theme = useSelector(getTheme);
    const [color, setColor] = useState('#ffffff');
    const [secondColor, setSecondColor] = useState('#5b5b5b')

    useEffect(() => {
        theme === 'dark' ? setColor('#ffffff') : setColor('#2C4A7E');
        theme === 'dark' ? setSecondColor('#5b5b5b') : setSecondColor('#afbbc6');
    }, [theme])

    return (
        <div className={styles.processRange}>
            <div className={styles.processRange__range}>
                <input type="range" min='0' max='100' step='1' value={process}
                       onChange={(e) => {
                           setProcess(e.target.value);
                           setValue(availableBalance * (e.target.value / 100))
                           // if (operation === 'Buy') {
                           //     const newValue = (total / coursePrice).toFixed(6)
                           //     setQuanity(newValue)
                           // }
                       }}/>
                <span className='rangeEmpty' />
                <span className='rangeFull' style={{width: `${process}%`}}/>
                <span className='thumb' style={{left: `${process}%`}}/>

                <span className='rangeCircle rangeCircle__0' onClick={() => {
                    setProcess(0);
                    setValue(0)
                }}
                      style={
                          process > 0 && process < 3 ? {borderColor: `${color}`, pointerEvents: 'all'} : {borderColor: `${color}`, pointerEvents: 'none'}}
                />
                <span className='rangeCircle rangeCircle__25' onClick={() => {
                    setProcess(25);
                    setValue(availableBalance * 0.25);
                }}
                      style={
                          process >= 25 && process < 28 ? {borderColor: `${color}`, pointerEvents: 'none'} :
                              process > 23 && process < 25 ? {borderColor: `${secondColor}`, pointerEvents: 'none'} :
                                  process > 27 ? {borderColor: `${color}`, pointerEvents: 'all'} : {borderColor: `${secondColor}`}}
                />
                <span className='rangeCircle rangeCircle__50' onClick={() => {
                    setProcess(50);
                    setValue(availableBalance * 0.5)
                }}
                      style={
                          process >= 50 && process < 53 ? {borderColor: `${color}`, pointerEvents: 'none'} :
                              process > 48 && process < 50 ? {borderColor: `${secondColor}`, pointerEvents: 'none'} :
                                  process > 52 ? {borderColor: `${color}`, pointerEvents: 'all'} : {borderColor: `${secondColor}`}}
                />
                <span className='rangeCircle rangeCircle__75' onClick={() => {
                    setProcess(75);
                    setValue(availableBalance * 0.75)
                }}
                      style={
                          process >= 75 && process < 78 ? {borderColor: `${color}`, pointerEvents: 'none'} :
                              process > 73 && process < 75 ? {borderColor: `${secondColor}`, pointerEvents: 'none'} :
                                  process > 77 ? {borderColor: `${color}`, pointerEvents: 'all'} : {borderColor: `${secondColor}`}}
                />
                <span className='rangeCircle rangeCircle__100' onClick={() => {
                    setProcess(100);
                    setValue(availableBalance);
                }}
                      style={
                              process > 98 && process < 100 ? {borderColor: `${secondColor}`, pointerEvents: 'none'} :
                                  process > 99 ? {borderColor: `${color}`, pointerEvents: 'none'} : {borderColor: `${secondColor}`, pointerEvents: 'all'}}
                />
            </div>
            <div className={styles.processRange__value}>
                <span>{process}%</span>
                <span>100%</span>
            </div>
        </div>
    )
}

export default ProcessRange;