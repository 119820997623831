import React, {useEffect} from "react";
import styles from './PriceChoose.module.scss';
import {useAppSelector} from "../../../../hooks/redux";
import {getText} from "../../../../store/selectors";

const PriceChoose = ({priceChoose, setPriceChoose, setCoursePrice, currentTicker, pro}) => {
    return (
        <div className={styles.priceChoose}>
            <div className={`${styles.priceChoose__item} choose`}>
                <input checked={priceChoose === 'market'} onChange={() => {
                    setPriceChoose('market');
                    setCoursePrice(currentTicker ? currentTicker.price : 0)
                }}
                    type="radio" name='priceToggle' id='priceMarket' />
                <p className='bgBlock'>{pro.market}</p>
            </div>
            <div className={`${styles.priceChoose__item} choose`}>
                <input checked={priceChoose === 'limit'} onChange={() => setPriceChoose('limit')}
                    type="radio" name='priceToggle' id='priceLimit' />
                <p className='bgBlock'>{pro.limit}</p>
            </div>
        </div>
    )
}

export default PriceChoose;