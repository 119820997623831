import React, {useState} from "react";
import HistoryCVVBtn from "../HistoryCVVBtn/HistoryCVVBtn";
import TransactionTypeCVV from "./TransactionTypeCVV/TransactionTypeCVV";
import TransactionListCVV from "./TransactionListCVV/TransactionListCVV";
import btcIcon from "../../../../assets/currencyIcons/btc.svg";
import usdtIcon from "../../../../assets/currencyIcons/usdt.svg";
import bnbIcon from "../../../../assets/currencyIcons/bnb.svg";
import {PeriodDateTwo} from "../../../Common/PeriodDateTwo/PeriodDateTwo";
import TransactionsCurrency from "../../../Common/TransactionsCurrency/TransactionsCurrency";


const TransactionContentCVV = ({account}) => {
    const [periodTransaction, setPeriodTransaction] = useState('7');
    const [startDate, setStartDate] = useState('');
    const [finishDate, setFinishDate] = useState('');
    const [currencyFilter, setCurrencyFilter] = useState('all')
    const transactionsList = [
        {icon: btcIcon, name: 'BTC'},
        {icon: btcIcon, name: 'USDT'},
        {icon: bnbIcon, name: 'BNB'},
        {icon: bnbIcon, name: 'BNB'},
        {icon: btcIcon, name: 'BTC'},
        {icon: usdtIcon, name: 'USDT'},
        {icon: usdtIcon, name: 'USDT'},
    ];

    return (
        <>
            <TransactionTypeCVV />
            <PeriodDateTwo
                text={account.period}
                period={periodTransaction}
                setPeriod={setPeriodTransaction}
                startDate={startDate}
                setStartDate={setStartDate}
                finishDate={finishDate}
                setFinishDate={setFinishDate}
            />
            <TransactionsCurrency currencyFilter={currencyFilter} setCurrencyFilter={setCurrencyFilter} />
            <TransactionListCVV transactionsList={transactionsList} />
            <HistoryCVVBtn text={account.get} />
        </>
    )
}

export default TransactionContentCVV;