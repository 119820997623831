import React, {useEffect, useState} from "react";
import styles from './AML.module.scss';
import AmlStep from "./AmlStep/AmlStep";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getText} from "../../../store/selectors";
import AmlStepFirst from "./AmlNetworks/AmlStepFirst";
import AmlStepSecond from "./AmlStepSecond/AmlStepSecond";
import AmlHeader from "./AmlHeader/AmlHeader";
import {getAllNetworkList, getTokensAML, refreshAccessToken} from "../../../api/api";
import {setAcToken} from "../../../store/account-slice";
import {useAppDispatch} from "../../../hooks/redux";
import AmlResult from "./AmlResult/AmlResult";
import {Preloader} from "../../Common/Preloader/Preloader";

const AML = () => {
    const {aml} = useSelector(getText);

    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(1);
    const [tokenList, setTokenList] = useState(null);
    const [networkList, setNetworkList] = useState(null);
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!tokenList) {
            getTokensAML(accessToken).then((r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getTokensAML(r.access_token).then((r => {
                                if (r) {
                                    setTokenList(r)
                                    getAllNetworkList(r.access_token).then(res => {
                                        if (r && r.length > 0) {
                                            setNetworkList(res)
                                        }
                                    })
                                }
                            }));
                        }
                    });
                } else if (r) {
                    setTokenList(r)
                    getAllNetworkList(accessToken).then(res => {
                        if (r && r.length > 0) {
                            setNetworkList(res)
                        }
                    })
                }
            }))
        }
    },[tokenList])

    return (
        <div className={`${styles.aml} bgMain`}>
            <AmlHeader headerText={aml.title} step={step} setStep={setStep} />
            <AmlStep step={step} text={aml} />
            {step === 1 && (
                <AmlStepFirst setStep={setStep} tokenList={tokenList} text={aml}/>
            )}
            {step === 2 && (
                <AmlStepSecond setStep={setStep} setResult={setResult} text={aml} networks={networkList} setIsLoading={setIsLoading} />
            )}
            <AmlResult result={result} setResult={setResult} text={aml} />
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default AML;