import React from "react";
import styles from './Price.module.scss';

const Price = ({operation, setOperation, priceChoose, coursePrice, setCoursePrice, setValue, setProcess, currentTicker, setIsError, setTotal, pro}) => {
    const handleChange = (value) => {
        if (priceChoose === 'limit') {
            const newValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            setCoursePrice(newValue)
        }
    }

    const changeOperation = (value) => {
        setOperation(value);
        setValue(0);
        setProcess(0);
        setTotal(0);
        setIsError(false);
    }

    return (
        <div className={styles.priceWrapper}>
            <div className={`${styles.price} bgBlock`}>
                <div className={styles.price__header}>
                    <p>{priceChoose === 'market' ? pro.marketPrice : pro.limitPrice}</p>
                    {currentTicker && currentTicker.token2 && (
                        <p>{currentTicker.token2}</p>
                    )}
                </div>
                <div className={styles.price__value}>
                    {priceChoose === 'market' && (
                        <span>{priceChoose === 'market' ? '≈' : null}</span>
                    )}
                    <input type="text" readOnly={priceChoose === 'market'} value={coursePrice}
                           onChange={(e) => handleChange(e.target.value)}/>
                </div>
            </div>
            <div className={styles.price__toggler}>
                <div className={styles.price__buy}>
                    <input checked={operation === 'Buy'} onChange={() => changeOperation('Buy')}
                           type="radio" name='barter' id='buy'/>
                    <p className='bgBlock'>{pro.buy}</p>
                </div>
                <div className={styles.price__sell}>
                    <input checked={operation === 'Sell'} onChange={() => changeOperation('Sell')}
                           type="radio" name='barter' id='sell'/>
                    <p className='bgBlock'>{pro.sell}</p>
                </div>
            </div>
        </div>

    )
}

export default Price;