import styles from './App.module.scss';
import './theme.scss';
import React, {useEffect, useState} from "react";
import Main from "./components/Main/Main";
import {Routes, Route} from "react-router-dom"
import Navigation from "./components/Navigation/Navigation";
import Pro from "./components/Pro/Pro";
import Wallet from "./components/Wallet/Wallet";
import Manager from "./components/Manager/Manager";
import History from "./components/History/History";
import {useSelector} from "react-redux";
import {getEvent, getLanguage, getRefToken, getTheme, getUserId} from "./store/selectors";
import {getLanguages} from "./language/languages";
import {useAppDispatch} from "./hooks/redux";
import {setText} from "./store/language-slice";
import Account from "./components/Account/Account";
import {setAcToken, setRefToken, setStateTheme, setTelegramId, setTwoFactorAuth} from "./store/account-slice";
import WalletItem from "./components/Wallet/WalletItem/WalletItem";
import Verification from "./components/Account/Verification/Verification";
import HistoryCVV from "./components/Account/HistoryCVV/HistoryCVV";
import WithdrawWallet from "./components/Wallet/WithdrawWallet/WithdrawWallet";
import DepositWallet from "./components/Wallet/DepositWallet/DepositWallet";
import Withdraw from "./components/Withdraw/Withdraw";
import Deposit from "./components/Deposit/Deposit";
import VerificationOptions from "./components/Account/VerificationOptions/VerificationOptions";
import {getUserInfo, refreshAccessToken} from "./api/api";
import {setBalance, setMainList} from "./store/main-slice";
import WalletsManager from "./components/Wallet/WalletsManager/WalletsManager";
import VerificationGoogleOff from "./components/Account/VerificationGoogleOff/VerificationGoogleOff";
import GoogleOffConfirm from "./components/Account/VerificationGoogleOff/GoogleOffConfirm/GoogleOffConfirm";
import VerificationKyc from "./components/Account/VerificationKyc/VerificationKyc";
import EventPopup from "./components/Common/EventPopup/EventPopup";
import bgSecond from "./assets/icons/bgMain.png";
import AML from "./components/Account/AML/AML";
import {useNavigate} from "react-router";
import Language from "./components/Account/Language/Language";


const App = () => {
    const language = useSelector(getLanguage);
    const dispatch = useAppDispatch();
    const themeScheme = useSelector(getTheme);
    const event = useSelector(getEvent);
    const [theme, setTheme] = useState('dark');
    const fix = 0;
    const telegramId = useSelector(getUserId);
    const refreshToken = useSelector(getRefToken);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const userId = params.get('telegram_id');
    const refToken = params.get('token');
    const navigate = useNavigate();

    useEffect(() => {
        getLanguages(language).then(response => dispatch(setText(response)))
    }, [language])

    useEffect(() => {
        dispatch(setStateTheme(theme))
    },[fix])

    useEffect(() => {
        if (telegramId && userId) {
            navigate('/')
        }
    }, [telegramId]);

    useEffect(() => {
        if (refreshToken) {
            refreshAccessToken(refreshToken).then(r => {
                if (r && r.access_token) {
                    dispatch(setAcToken(r.access_token));
                    getUserInfo(telegramId, r.access_token).then(r => {
                        if (r && r.total_balance) {
                            dispatch(setBalance(r.total_balance));
                            dispatch(setStateTheme(r.theme))
                            dispatch(setMainList(r.cryptocurrency));
                            dispatch(setTwoFactorAuth(r.fa_enable));
                        }
                    });
                }
            })
        }

    }, [refreshToken])

    useEffect(() => {
        if (userId && refToken) {
            dispatch(setTelegramId(userId));
            dispatch(setRefToken(refToken));
            // getUserInfo(userId, accessToken).then(r => {
            //     if (r && r.total_balance) {
            //         dispatch(setBalance(r.total_balance));
            //         dispatch(setStateTheme(r.theme))
            //     }
            // });
        }
    }, [fix]);

    useEffect(() => {
        setTheme(themeScheme);
    },[themeScheme])

    return (
        <div className={`${styles.contentWrapper} ${theme}`}>
            <span className={'backgroundMain'} style={{ backgroundImage: `url(${bgSecond})`}}/>
            <Routes>
                <Route index element={<Main theme={theme} />} />
                <Route path={'/wallet/*'} element={<Wallet />} />
                <Route path={'/wallet/create/*'} element={<WalletsManager />} />
                <Route path={'/withdraw/*'} element={<Withdraw />} />
                <Route path={'/deposit/*'} element={<Deposit />} />
                <Route path={'/wallet/:id/*'} element={<WalletItem />} />
                <Route path={'/wallet/withdraw/*'} element={<WithdrawWallet />} />
                <Route path={'/wallet/deposit/*'} element={<DepositWallet />} />
                <Route path={'/pro/*'} element={<Pro />} />
                <Route path={'/manager/*'} element={<Manager />} />
                <Route path={'/history/*'} element={<History />} />
                <Route path={'/account/*'} element={<Account />} />
                <Route path={'/account/verification/*'} element={<Verification />} />
                <Route path={'/account/verificationKyc/*'} element={<VerificationKyc />} />
                <Route path={'/account/verificationOptions/*'} element={<VerificationOptions />} />
                <Route path={'/account/verificationGoogleOff/*'} element={<VerificationGoogleOff />} />
                <Route path={'/account/verificationGoogleOff/confirm/*'} element={<GoogleOffConfirm />} />
                <Route path={'/account/history/*'} element={<HistoryCVV />} />
                <Route path={'/account/aml/*'} element={<AML />} />
                <Route path={'/account/language/*'} element={<Language />} />
            </Routes>
            {event && (
                <EventPopup text={event.text} success={event.success} />
            )}
            <Navigation theme={theme} />
        </div>
    )
}

export default App;
