import React, {useEffect, useState} from "react";
import styles from './SpotContent.module.scss';
import SpotFilter from "./SpotFilter/SpotFilter";
import PriceChoose from "./PriceChoose/PriceChoose";
import Price from "./Price/Price";
import Exchange from "./Exchange/Exchange";
import ExchangeMainButton from "./ExchangeMainButton/ExhangeMainButton";
import OrderItemsList from "./OrderItemsList/OrderItemsList";
import FilterPopup from "./FilterPopup/FilterPopup";
import ProcessRange from "./ProcessRange/ProcessRange";
import {getOpenOrders, getTickersList, refreshAccessToken} from "../../../api/api";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setOpenOrders, setOrderHistory, setTickets} from "../../../store/pro-slice";
import {useSelector} from "react-redux";
import {
    getAcToken,
    getCouplesList,
    getEvent,
    getOpenOrdersList,
    getRefToken, getText,
    getUserId
} from "../../../store/selectors";
import notOrdersIcon from '../../../assets/icons/notOrders.svg';
import {Preloader} from "../../Common/Preloader/Preloader";
import {setAcToken} from "../../../store/account-slice";
import EventPopup from "../../Common/EventPopup/EventPopup";

const SpotContent = ({text}) => {
    const event = useSelector(getEvent);
    const {pro} = useAppSelector(getText);
    const couplesArr = useSelector(getCouplesList);
    const orderList = useSelector(getOpenOrdersList);
    const dispatch = useAppDispatch();
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const [operation, setOperation] = useState('Buy');
    const [priceChoose, setPriceChoose] = useState('market');
    const [showFilters, setShowFilters] = useState(false);
    const [process, setProcess] = useState(0);
    const [value, setValue] = useState(0);
    const [currentTicker, setCurrentTicker] = useState(null);
    const [coursePrice, setCoursePrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [total, setTotal] = useState(0)


    const updateDates = () => {
        if (telegramId && accessToken) {
            getTickersList(telegramId, accessToken).then((result => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            setIsLoading(true);
                            dispatch(setAcToken(r.access_token));
                            getTickersList(telegramId, r.access_token).then((response => {
                                if (r && r !== couplesArr) {
                                    dispatch(setTickets(response));
                                    setCurrentTicker(response[0]);
                                    setIsLoading(false);
                                }
                            }))
                        }
                    });
                }
                if (result && result !== couplesArr) {
                    dispatch(setTickets(result));
                    setCurrentTicker(result[0]);
                    setIsLoading(false);
                }
            }))
        } else {
            setTimeout(() => {
                setIsLoading(false);
            }, 3500)
        }
    }

    const updateOpenOrders = () => {
        getOpenOrders(telegramId, accessToken).then(response => {
            if (response && response.detail && response.detail === 'access_token expired') {
                refreshAccessToken(refreshToken).then(r => {
                    if (r && r.access_token) {
                        setIsLoading(true);
                        dispatch(setAcToken(r.access_token));
                        getOpenOrders(telegramId, r.access_token).then(result => {
                            if (r) {
                                dispatch(setOpenOrders(result))
                            }
                        })
                    }
                });
            } else {
                dispatch(setOpenOrders(response))
            }
        });
    }

    useEffect(() => {
        updateDates();
        if (telegramId && accessToken && orderList && orderList.length === 0) {
            updateOpenOrders()
        }
    }, [accessToken])

    useEffect(() => {
        if (currentTicker && currentTicker.price) {
            setCoursePrice(currentTicker.price);
            setIsLoading(false);
            setIsError(false);
        }
    }, [currentTicker])

    return (
        <div className={styles.spotContent}>
            <SpotFilter currentTicker={currentTicker} setShowPopup={setShowFilters}/>
            <PriceChoose priceChoose={priceChoose} setPriceChoose={setPriceChoose} setCoursePrice={setCoursePrice}
                         currentTicker={currentTicker} pro={pro} />
            <Price operation={operation} setOperation={setOperation} priceChoose={priceChoose} coursePrice={coursePrice}
                   setCoursePrice={setCoursePrice} setProcess={setProcess} setValue={setValue} setTotal={setTotal}
                   currentTicker={currentTicker} setIsError={setIsError} pro={pro} />
            <Exchange value={value} setValue={setValue} coursePrice={coursePrice} currentTicker={currentTicker}
                      setIsError={setIsError} operation={operation} total={total} setTotal={setTotal} process={process} pro={pro}/>
            {currentTicker && (
                <ProcessRange
                    process={process}
                    setProcess={setProcess}
                    availableBalance={currentTicker && currentTicker.balance2 && operation === 'Buy' ? currentTicker.balance2
                        : currentTicker && currentTicker.balance && operation === 'Sell' ? currentTicker.balance : 0}
                    setValue={operation === 'Sell' ? setValue : setTotal}
                    operation={operation}
                    setQuanity={setValue}
                    total={total}
                    coursePrice={coursePrice}
                />
            )}


            <div className={styles.balance}>
                <p className={isError ? styles.error : null}>{pro.availableBalance}</p>
                {currentTicker && (
                    <p className={isError ? styles.error : null}>{operation === 'Buy' ? currentTicker.balance2 : currentTicker.balance}</p>
                )}
            </div>
            <ExchangeMainButton value={value} currentTicker={currentTicker} operation={operation} setCoursePrice={setCoursePrice}
                                updateDates={updateDates} setProcess={setProcess} setTotal={setTotal} setValue={setValue} pro={pro}
                                priceChoose={priceChoose} coursePrice={coursePrice} setIsLoading={setIsLoading} updateOpenOrders={updateOpenOrders}/>
            {orderList && orderList.length > 0 && (
                <p className={`${styles.orderListLabel} bgButtonLabel`}>{pro.openOrders}</p>
            )}
            <FilterPopup text={text} showFilters={showFilters} setShowFilters={setShowFilters}
                         setCurrentTicker={setCurrentTicker} couplesArr={couplesArr} setProcess={setProcess}
                         setSellValue={setValue}/>
            {orderList && orderList.length
                ? <OrderItemsList orderList={orderList} setIsLoading={setIsLoading}/>
                : <div className={styles.notOrders}>
                    <img src={notOrdersIcon} alt=""/>
                    <p>{pro.noOpenOrders}</p>
                </div>
            }
            {event && (
                <EventPopup text={event.text} success={event.success} />
            )}
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default SpotContent;