import React from "react";
import styles from './Content.module.scss';
import {useSelector} from "react-redux";
import {getPictureSrc, getWallets} from "../../../store/selectors";
import {Link} from "react-router-dom";

const Content = () => {
    const list = useSelector(getWallets);
    const pictureSrc = getPictureSrc;


    return (
        <ul className={`${styles.content} bgMain`}>
            {list.length > 0 && (
                list.map((item, index) => (
                    <li key={index} >
                        <Link to={'/wallet/' + item.id} className={styles.content__item}>
                            <div className={styles.content__itemCurrency}>
                                {list.length > 0 && (
                                    <img src={`${pictureSrc}${item.icon}`} alt=""/>
                                )}
                                <p>{item.name}</p>
                            </div>
                            <p>{item.finalBalance}</p>
                        </Link>
                    </li>
                ))
            )}
        </ul>
    )
}

export default Content;