import React from "react";
import styles from './Pro.module.scss';
import {PagesHeader} from "../Common/PagesHeader/PagesHeader";
import ExchangeChoose from "./ExchangeChoose/ExchangeChoose";
import ExchangeContent from "./ExchangeContent/ExchangeContent";
import SpotContent from "./SpotContent/SpotContent";
import {getText} from "../../store/selectors";
import {Route, Routes, useLocation, useNavigate} from "react-router";
import {useAppSelector} from "../../hooks/redux";

const Pro = () => {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const {pro} = useAppSelector(getText);

    if (location === '/pro' || location === '/pro/') {
        navigate('/pro/spot/');
    }

    return (
        <div className={`${styles.pro} bgMain`}>
            <PagesHeader headerText={pro.headerTitle} menu />
            <ExchangeChoose swapText={pro.swap} spotText={pro.spot} />
            <Routes>
                <Route path={'spot/*'} element={<SpotContent text={pro} />} />
                <Route path={'exchange/*'} element={<ExchangeContent />} />
            </Routes>
        </div>
    )
}

export default Pro;