import React, {useState} from "react";
import styles from './WalletFilter.module.scss';


const WalletFilter = ({text}) => {
    const [filterValue, setFilterValue] = useState('all');
    const filters = [
        {name: text, value: 'all'},
        {name: 'crypto', value: 'crypto'},
        {name: 'fiat', value: 'fiat'},
    ]

    return (
        <div className={styles.walletFilter}>
            {filters && (
                filters.map((item, index) => (
                    <div key={index} className={`${styles.walletFilter__checkbox} switch`}>
                        <input type="radio" id='all' name='walletFilter' onChange={() => setFilterValue(item.value)}/>
                        <div className={`${styles.walletFilter__label} bgBlock`}>
                            <span className='circle' />
                            <span className={styles.text}>{item.name}</span>
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default WalletFilter;