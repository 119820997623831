import React from "react";
import styles from './HistoryOrderValue.module.scss';

const HistoryOrderValue = ({type, side, amount, price}) => {
    return (
        <div className={styles.historyOrderValue}>
            <div>
                <p className={styles.historyOrderValue__label}>
                    side
                </p>
                <p className={side === 'BUY' ? styles.limitBuy : styles.limitSell}>
                    {side}
                </p>
            </div>
            <div>
                <p className={styles.historyOrderValue__label}>
                    Type
                </p>
                <p className={styles.historyOrderValue__value}>
                    {type}
                </p>
            </div>
            <div>
                <p className={styles.historyOrderValue__label}>
                    Amount
                </p>
                <p className={styles.historyOrderValue__value}>
                    {amount}
                </p>
            </div>
            <div>
                <p className={styles.historyOrderValue__label}>
                    Orders price
                </p>
                <p className={styles.historyOrderValue__value}>
                    {price}
                </p>
            </div>
        </div>
    )
}

export default HistoryOrderValue;