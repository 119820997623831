import React, {useEffect, useState} from "react";
import styles from './HistoryOrdersPopup.module.scss';
import close from "../../../../assets/icons/close.svg";
import closeBlue from "../../../../assets/icons/closeBlue.svg";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getText, getTheme, getUserId} from "../../../../store/selectors";
import {PeriodDateTwo} from "../../../Common/PeriodDateTwo/PeriodDateTwo";
import TransactionsCurrency from "../../../Common/TransactionsCurrency/TransactionsCurrency";
import OrdersCVVList from "../../OrdersCVVList/OrdersCVVList";
import HistoryOrdersActionsCVV from "../../HistoryOrdersActionsCVV/HistoryOrdersActionsCVV";
import FilterThreeTypes from "../../../Common/FilterThreeTypes/FilterThreeTypes";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {getAllTickers, getQRCode, refreshAccessToken} from "../../../../api/api";
import {setAcToken} from "../../../../store/account-slice";

const HistoryOrdersPopup = ({showOrders, setShowOrders, startDate, setStartDate, finishDate,
                                setFinishDate, ordersChoose, setOrdersChoose, setSelectedTicker, onGetCVV}) => {
    const {history} = useAppSelector(getText);
    const telegramId = useAppSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const {account} = useSelector(getText);
    const theme = useSelector(getTheme);
    const [ordersCVV, setOrdersCVV] = useState(null);
    const [periodTransaction, setPeriodTransaction] = useState('7');
    const [currencyFilter, setCurrencyFilter] = useState('all');

    useEffect(() => {
        if (telegramId && !ordersCVV && accessToken) {
            getAllTickers(telegramId, accessToken).then(r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getAllTickers(telegramId, r.access_token).then(r => {
                                if (r && !r.error) {
                                    setOrdersCVV(r)
                                }
                            })
                        }
                    });
                }
                if (r && !r.error && !r.detail) {
                    setOrdersCVV(r)
                }
            })
        }
    }, []);

    return (
        <div
            className={showOrders ? `${styles.historyOrdersPopupActive} bgSecond` : `${styles.historyOrdersPopup} bgSecond`}>
            <div className={styles.close}>
                <button onClick={() => setShowOrders(false)}>
                    <img src={theme === 'dark' ? close : closeBlue} alt=""/>
                </button>
            </div>
            <div className={styles.ordersPopupContent}>
                <FilterThreeTypes
                    ordersChoose={ordersChoose}
                    setOrdersChoose={setOrdersChoose}
                    firstBtn={history.all}
                    secondBtn={history.limit}
                    thirtyBtn={history.market}
                />
                <PeriodDateTwo
                    text={account.period}
                    period={periodTransaction}
                    setPeriod={setPeriodTransaction}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    finishDate={finishDate}
                    setFinishDate={setFinishDate}
                />
                <TransactionsCurrency textCurrency={account.currency}
                                      currencyFilter={currencyFilter}
                                      setCurrencyFilter={setCurrencyFilter}
                />
                <OrdersCVVList historyList={ordersCVV} setChangedToken={setSelectedTicker} ticker/>
                <HistoryOrdersActionsCVV setShowOrders={setShowOrders} onGetCVV={onGetCVV}/>
            </div>
        </div>
    )
}

export default HistoryOrdersPopup;