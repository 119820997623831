import React, {useEffect, useState} from "react";
import styles from './FilterPopup.module.scss';
import backIcon from "../../../../assets/icons/back.svg";
import noFavorites from "../../../../assets/icons/noFavorites.svg";
import noFavoritesBlue from "../../../../assets/icons/noFavoritesBlue.svg";
import backIconBlue from "../../../../assets/icons/backBlue.svg";
import {useSelector} from "react-redux";
import {getTheme} from "../../../../store/selectors";
import {SearchFull} from "../../../Common/SearchFull/SearchFull";
import FilterCouple from "./FilterCouple/FilterCouple";
import CouplesList from "./CouplesList/CouplesList";
import {Preloader} from "../../../Common/Preloader/Preloader";

const FilterPopup = ({couplesArr, text, showFilters, setShowFilters, setCurrentTicker, setProcess, setSellValue}) => {
    const theme = useSelector(getTheme);
    const [tokenFilter, setTokenFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [list, setList] = useState([]);

    useEffect(() => {
        if (couplesArr && searchValue === '') {
            setList(couplesArr)
        } else if (couplesArr) {
            const result = couplesArr
                .filter(prof => {
                    return prof.ticker.toLowerCase().includes(searchValue.toLowerCase());
                })
                .sort((a, b) => {
                    if (a.ticker.toLowerCase().indexOf(searchValue.toLowerCase()) > b.ticker.toLowerCase().indexOf(searchValue.toLowerCase())) {
                        return 1;
                    } else if (a.ticker.toLowerCase().indexOf(searchValue.toLowerCase()) < b.ticker.toLowerCase().indexOf(searchValue.toLowerCase())) {
                        return -1;
                    } else {
                        if (a.ticker > b.ticker)
                            return 1;
                        else
                            return -1;
                    }
                });
            setList(result);
        }
    }, [couplesArr, searchValue])

    return (
        <div className={showFilters ? `${styles.filterPopupActive} bgMain` : `${styles.filterPopup} bgMain`}>
            <div className={styles.filterPopup__header}>
                <button onClick={() => setShowFilters(false)}>
                    <img src={theme === 'dark' ? backIcon : backIconBlue} alt=""/>
                </button>
                <h2>{text.title}</h2>
                <span/>
            </div>
            <SearchFull value={searchValue} setValue={setSearchValue}/>
            <FilterCouple tokenFilter={tokenFilter} setTokenFilter={setTokenFilter} setIsLoading={setIsLoading}/>
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
            <CouplesList couplesArr={list} setCurrentTicker={setCurrentTicker} setShowFilters={setShowFilters}
                         setProcess={setProcess} setSellValue={setSellValue}/>
            {couplesArr && couplesArr.length === 0 && (
                <div className={styles.empty}>
                    <img src={theme === 'dark' ? noFavorites : noFavoritesBlue} alt=""/>
                    <p>{text.noFavorites}</p>
                </div>
            )}
        </div>
    )
}

export default FilterPopup;