import React from "react";
import styles from './CouplesList.module.scss';
import CouplesItem from "./CouplesItem/CouplesItem";

const CouplesList = ({couplesArr, setCurrentTicker, setShowFilters, setProcess, setSellValue}) => {
    return (
        <ul className={styles.couplesList}>
            {couplesArr && !couplesArr.detail && couplesArr.length !== 0 && (
                couplesArr.map((item, index) => (
                    <CouplesItem key={index}
                                 item={item}
                                 setCurrentTicker={setCurrentTicker}
                                 setShowFilters={setShowFilters}
                                 setProcess={setProcess}
                                 setSellValue={setSellValue}
                    />
                ))
            )}
        </ul>
    )
}

export default CouplesList;