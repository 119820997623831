import React, {useEffect, useState} from "react";
import styles from './HistoryTransactionsPopup.module.scss';
import close from "../../../../assets/icons/close.svg";
import closeBlue from "../../../../assets/icons/closeBlue.svg";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getText, getTheme} from "../../../../store/selectors";
import FilterThreeTypes from "../../../Common/FilterThreeTypes/FilterThreeTypes";
import {PeriodDateTwo} from "../../../Common/PeriodDateTwo/PeriodDateTwo";
import TransactionsCurrency from "../../../Common/TransactionsCurrency/TransactionsCurrency";
import HistoryOrdersActionsCVV from "../../HistoryOrdersActionsCVV/HistoryOrdersActionsCVV";
import OrdersCVVList from "../../OrdersCVVList/OrdersCVVList";
import {getAllTokens, refreshAccessToken} from "../../../../api/api";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {setAcToken} from "../../../../store/account-slice";

const HistoryTransactionsPopup = ({showTransactionPopup, setShowTransactionPopup, startDate, setStartDate, finishDate, transactionChoose,
                                      setFinishDate, setTransactionsChoose, changedToken, setChangedToken, onGetCVV}) => {
    const {history} = useAppSelector(getText);
    const [transactionsCVV, setTransactionsCVV] = useState([])
    const {account} = useSelector(getText);
    const theme = useSelector(getTheme);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [periodTransaction, setPeriodTransaction] = useState('7');
    const [currencyFilter, setCurrencyFilter] = useState('all');

    useEffect(() => {
        if (transactionsCVV !== [])
            getAllTokens(accessToken).then(r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getAllTokens(r.access_token).then(r => {
                                setTransactionsCVV(r)
                            })
                        }
                    });
                } else {
                    setTransactionsCVV(r)
                }
            })
    }, [])

    return (
        <div className={showTransactionPopup
            ? `${styles.historyTransactionsPopupActive} bgSecond`
            : `${styles.historyTransactionsPopup} bgSecond`}
        >
            <div className={styles.close}>
                <button onClick={() => setShowTransactionPopup(false)}>
                    <img src={theme === 'dark' ? close : closeBlue} alt=""/>
                </button>
            </div>
            <FilterThreeTypes
                ordersChoose={transactionChoose}
                setOrdersChoose={setTransactionsChoose}
                firstBtn={history.all}
                secondBtn={history.send}
                thirtyBtn={history.receive}
            />
            <PeriodDateTwo
                text={account.period}
                period={periodTransaction}
                setPeriod={setPeriodTransaction}
                startDate={startDate}
                setStartDate={setStartDate}
                finishDate={finishDate}
                setFinishDate={setFinishDate}
            />
            <TransactionsCurrency textCurrency={account.currency}
                                  currencyFilter={currencyFilter}
                                  setCurrencyFilter={setCurrencyFilter}
            />
            <OrdersCVVList historyList={transactionsCVV} setChangedToken={setChangedToken}/>
            <HistoryOrdersActionsCVV setShowOrders={setShowTransactionPopup} onGetCVV={onGetCVV}/>
        </div>
    )
}

export default HistoryTransactionsPopup;