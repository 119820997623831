import React from "react";
import styles from './SpotFilter.module.scss';
import downIcon from '../../../../assets/icons/down.svg';
import downIconBlue from '../../../../assets/icons/downBlue.svg';
import {getPictureSrc, getText, getTheme} from "../../../../store/selectors";
import {useAppSelector} from "../../../../hooks/redux";

const SpotFilter = ({currentTicker, setShowPopup}) => {
    const theme = useAppSelector(getTheme);
    const pictureSrc = getPictureSrc;

    return (
        <div className={styles.spotFilter}>
            <div className={`${styles.spotFilter__filter} bgBlock`}>
                <button onClick={() => setShowPopup(true)}>
                    {currentTicker && currentTicker.icon && (
                        <img className={styles.currencyIcon} src={`${pictureSrc}${currentTicker.icon}`} alt=""/>
                    )}
                    {currentTicker && currentTicker.ticker && (
                        <span>{currentTicker.ticker}</span>
                    )}
                    {currentTicker && currentTicker.price && (
                        <p style={{color: '#325048'}}>{currentTicker.price}</p>
                    )}
                    <img className={styles.arrow} src={theme === 'dark' ? downIcon : downIconBlue} alt=""/>
                </button>
            </div>
        </div>
    )
}

export default SpotFilter;