import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    orders: [],
    openOrders: [],
    couples: []
};

export const proSlice = createSlice({
    name: 'proPage',
    initialState,
    reducers: {
        removeOrder(state, {payload}) {
            state.openOrders = state.openOrders.filter(item => item.order_id !== payload);
        },
        setOrderHistory(state, {payload}) {
            state.orders = payload;
        },
        setOpenOrders(state, {payload}) {
            state.openOrders = payload;
        },
        setTickets(state, {payload}) {
            state.couples = payload;
        },
        setFavorite(state, {payload}) {
            state.couples = state.couples.map((item) => {
                if (item.ticker_id === payload) {
                    item.isFavorite = !item.isFavorite;
                }
                return item;
            });
            state.couples = state.couples.sort((a, b) =>  b.isFavorite - a.isFavorite)
        }
    }
})

export default proSlice.reducer;

export const {
    removeOrder,
    setOrderHistory,
    setFavorite,
    setTickets,
    setOpenOrders
} = proSlice.actions;