import React from "react";
import searchIcon from "../../../assets/icons/search-grey.svg";
import searchIconBlue from "../../../assets/icons/search.svg";
import styles from './SearchFull.module.scss';
import {useSelector} from "react-redux";
import {getTheme} from "../../../store/selectors";

export const SearchFull = ({placeholder='', value, setValue}) => {
    const theme = useSelector(getTheme);
    return (
        <div className={styles.searchFull}>
            <img className='searchIcon' src={theme === 'dark' ? searchIcon : searchIconBlue} alt=""/>
            <input className='searchIcon' placeholder={placeholder} type="text" value={value} onChange={(e) => setValue(e.target.value)}/>
        </div>
    )
}