import React, {useEffect, useState} from "react";
import styles from './Language.module.scss';
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import {getLanguage, getText} from "../../../store/selectors";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setLanguage} from "../../../store/language-slice";
import {useSelector} from "react-redux";

const Language = () => {
    const language = useAppSelector(getLanguage);
    const {account} = useSelector(getText);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('english');

    const handleChange = (languageValue) => {
        setValue(languageValue);
        dispatch(setLanguage(languageValue))
    }

    useEffect(() => {
        if (language) {
            setValue(language)
        }
    }, [language])

    return (
        <div className={`${styles.language} bgMain`}>
            <PagesHeader headerText={account.language} />
            <ul>
                <li className='switch'>
                    <div className={styles.language__header}>
                        <p>{account.english}</p>
                    </div>
                    <span className='circle' />
                    <input checked={value === "english"} type="radio" name='ordersCVV' onChange={() => handleChange('english')} />
                </li>
                <li className='switch'>
                    <div className={styles.language__header}>
                        <p>{account.russian}</p>
                    </div>
                    <span className='circle' />
                    <input checked={value === "russian"} type="radio" name='ordersCVV' onChange={() => handleChange('russian')} />
                </li>
            </ul>
        </div>
    )
}

export default Language;