import React, {useEffect, useState} from "react";
import styles from './PeriodDateTwo.module.scss';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DateField} from "@mui/x-date-pickers/DateField";
import dayjs from "dayjs";
import {useAppSelector} from "../../../hooks/redux";
import {getText} from "../../../store/selectors";

export const PeriodDateTwo = ({text, period, setPeriod, startDate, setStartDate, finishDate, setFinishDate}) => {
    const {history} = useAppSelector(getText);
    const [detail, setDetail] = useState(null);

    const handleMask = (event) => {
        setDetail(event.detail);
    };
    const modify = (input) => {
        return undefined;
    };

    const setDate = (value) => {
        setPeriod(`${value}`)
        const nowDate = new Date();
        setFinishDate(dayjs(`${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`));
        let date = new Date();
        date.setDate(date.getDate() - value);
        const newDate = date.toISOString();
        setStartDate(dayjs(`${newDate.substring(4, 0)}/${newDate.slice(5, 7)}/${newDate.slice(8, 10)}`));
    }

    useEffect(() => {
        if (startDate === '' && finishDate === '') {
            setDate(7);
        }
    });

    return (
        <>
            <div className={styles.periodDateTwo}>
                <div className={`${styles.periodDateTwo__button} choose`}>
                    <input checked={period === '0'} type="radio" name='historyTransactionPeriod'
                           onChange={() => setDate(0)}/>
                    <p className='bgBlock'>{history.oneDay}</p>
                </div>
                <div className={`${styles.periodDateTwo__button} choose`}>
                    <input checked={period === '7'} type="radio" name='historyTransactionPeriod'
                           onChange={() => setDate(7)}/>
                    <p className='bgBlock'>{history.sevenDay}</p>
                </div>
            </div>
            <div className={styles.dateFields}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateField', 'DateField']}>
                        <DateField
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            format="YYYY/MM/DD"
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateField', 'DateField']}>
                        <DateField
                            value={finishDate}
                            onChange={(newValue) => setFinishDate(newValue)}
                            format="YYYY/MM/DD"
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        </>

    )
}
