import React from "react";
import styles from './EventPopup.module.scss';
import successIcon from '../../../assets/icons/success.svg';
import failedIcon from '../../../assets/icons/failed.svg';
import {useAppDispatch} from "../../../hooks/redux";
import {setEventPopup} from "../../../store/main-slice";


const EventPopup = ({text, success}) => {
    const dispatch = useAppDispatch();
    const onSubmit = () => {
        dispatch(setEventPopup(null));
    }

    return (
        <div className={styles.wrapper}>
            <div className={`${styles.eventPopup} bgSecond`}>
                <img src={success ? successIcon : failedIcon} alt=""/>
                <p className={styles.eventPopup__text}>{text}</p>
                <button className={`${styles.eventPopup__action} action`} onClick={() => onSubmit()}>
                    <span>Ok</span>
                </button>
            </div>
        </div>
    )
}

export default EventPopup;