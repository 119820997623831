import React from "react";
import styles from './ExutedProcess.module.scss';

const ExecutedProcess = ({textDay, textStatus, textToday}) => {
    const currentDate = new Date();
    const hours = currentDate.getHours();

    return (
        <div className={`${styles.executedProcess} bgBlock`}>
            <div>
                <p>{textDay}</p>
                <p>{textToday} {currentDate.getHours()}:{currentDate.getMinutes() <= 9 ? '0' : null}{currentDate.getMinutes()}</p>
            </div>
            <div>
                <p>{textStatus}</p>
                <p className={styles.process}>Processing</p>
            </div>
        </div>
    )
}

export default ExecutedProcess;