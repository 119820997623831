import React, {useEffect, useState} from "react";
import styles from './Verification.module.scss';
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getText, getTheme, getUserId} from "../../../store/selectors";
import Steps from "./Steps/Steps";
import VerificationHeader from "./VerificationHeader/VerificationHeader";
import DownloadApp from "./DownloadApp/DownloadApp";
import CopyCode from "./CopyCode/CopyCode";
import VerificationButton from "./VerificationButton/VerificationButton";
import SendCode from "./SendCode/SendCode";
import {getAuthCode, refreshAccessToken} from "../../../api/api";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../../hooks/redux";
import {setAcToken} from "../../../store/account-slice";

const Verification = () => {
    const navigate = useNavigate();
    const theme = useSelector(getTheme);
    const {account} = useSelector(getText);
    const [step, setStep] = useState(1);
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [verificationCode, setVerificationCode] = useState('')
    const [sendCode, setSendCode] = useState('');

    useEffect(() => {
        if (telegramId && step === 2) {
            getAuthCode(telegramId, accessToken).then((response) => {
                if (response && response.detail && response.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getAuthCode(telegramId, r.access_token).then((response) => {
                                if (response && response.code) {
                                    setVerificationCode(response.code);
                                    // navigate('/account/verificationOptions/');
                                }
                            })
                        }
                    });
                }
                if (response && response.code) {
                    setVerificationCode(response.code);
                    // navigate('/account/verificationOptions/');
                }
            })
        }
    }, [step])

    return (
        <div className={`${styles.verification} bgMain`}>
            <VerificationHeader headerText={account.verification} step={step} setStep={setStep} />
            <Steps step={step} text={account.downloadText} />
            {step && step === 1 && (
                <DownloadApp theme={theme} text={account.downloadLink} />
            )}
            {step && step === 2 && (
                <CopyCode verificationCode={verificationCode} theme={theme} />
            )}
            {step && step === 3 && (
                <SendCode labelText={account.sendAuthCode} inputText={account.code6} sendCode={sendCode} setSendCode={setSendCode} />
            )}
            <VerificationButton text={account.enter} step={step} setStep={setStep} code={sendCode} />
        </div>
    )
}

export default Verification;