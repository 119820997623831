import React, {useEffect} from "react";
import styles from './HistoryCVV.module.scss';
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import {useSelector} from "react-redux";
import {getText} from "../../../store/selectors";
import HistoryType from "./HistoryType/HistoryType";
import {Route, Routes, useLocation, useNavigate} from "react-router";
import OrdersContentCVV from "./OrdersContentCVV/OrdersContentCVV";
import TransactionContentCVV from "./TransactionContentCVV/TransactionContentCVV";

const HistoryCVV = () => {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const {account} = useSelector(getText);

    useEffect(() => {
        if (location === '/account/history' || location === '/account/history/') {
            navigate('/account/history/orders/');
        }
    })

    return (
        <div className={`${styles.historyCVV} bgMain`}>
            <PagesHeader headerText={account.historyCVV} />
            <HistoryType />
            <Routes>
                <Route path={'orders/*'} element={<OrdersContentCVV account={account} />} />
                <Route path={'transactions/*'} element={<TransactionContentCVV account={account} />} />
            </Routes>
        </div>
    )
}

export default HistoryCVV;