import React from "react";
import styles from './TransactionsList.module.scss';
import {useSelector} from "react-redux";
import {getHistoryTransactions} from "../../../../store/selectors";
import TransactionItem from "./TransactionItem/TransactionItem";

const TransactionsList = ({transactionsHistoryList, setSelectedTransaction}) => {
    const transactions = useSelector(getHistoryTransactions);

    return (
        <ul className={styles.transactionsList}>
            {transactionsHistoryList && (
                transactionsHistoryList.map((item, index) => (
                    <TransactionItem key={index} item={item} setSelectedTransaction={setSelectedTransaction} />
                ))
            )}
        </ul>
    )
}

export default TransactionsList;