import React from "react";
import styles from './ManagerChoose.module.scss';

const ManagerChoose = ({managerChoose, setManagerChoose}) => {
    return (
        <div className={styles.managerChoose}>
            <div className={`${styles.managerChoose__button} choose`}>
                <input checked={managerChoose === 'crypto'} type="radio" name='manager' id='crypto'
                       onChange={() => setManagerChoose('crypto')} />
                <p className='bgBlock'>Crypto</p>
            </div>
            <div className={`${styles.managerChoose__button} choose`}>
                <input checked={managerChoose === 'fiat'} type="radio" name='manager' id='crypto'
                       onChange={() => setManagerChoose('fiat')} />
                <p className='bgBlock'>Fiat</p>
            </div>
        </div>
    )
}

export default ManagerChoose;