import React, {useState} from "react";
import styles from './Manager.module.scss';
import {PagesHeader} from "../Common/PagesHeader/PagesHeader";
import ManagerChose from "./ManagerChose/ManagerChoose";
import {useSelector} from "react-redux";
import {getText, getWallets} from "../../store/selectors";
import ManagerCryptoList from "./ManagerCryptoList/ManagerCryptoList";
import bgSecond from "../../assets/icons/bGnew2.png";

const Manager = () => {
    const {manager} = useSelector(getText);
    const wallets = useSelector(getWallets);
    const [managerChoose, setManagerChoose] = useState('crypto');


    return (
        <div className={`${styles.manager} bgMain`}>
            <PagesHeader headerText={manager.title} menu />
            <ManagerChose managerChoose={managerChoose} setManagerChoose={setManagerChoose} />
            <div className={styles.list}>
                <ManagerCryptoList list={wallets} />
            </div>
        </div>
    )
}

export default Manager;