import React from "react";
import styles from './HistoryOrderItem.module.scss';
import HistoryOrderValue from "./HistoryOrderValue/HistoryOrderValue";

const HistoryOrderItem = ({item, setSelectedOrder}) => {
    return (
        <li className={`${styles.historyOrderItem}`} onClick={() => setSelectedOrder(item)}>
            <div className={styles.historyOrderItem__header}>
                <div className={styles.historyOrderItem__headerCurrency}>
                    <p>{item.name}</p>
                    <span className={item.isAccepted ? styles.accept : styles.cancel}>
                        {item.isAccepted ? 'Accepted' : 'Cancelled'}
                    </span>
                </div>
                <div className={styles.historyOrderItem__headerData}>
                    <span>{item.date}</span>
                    <span>{item.time}</span>
                </div>
            </div>
            <span className='decoration' />
            <HistoryOrderValue side={item.side} price={item.price} amount={item.amount} type={item.order} />
        </li>
    )
}

export default HistoryOrderItem;