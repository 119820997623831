import React from "react";
import styles from './FilterThreeTypes.module.scss';

const FilterThreeTypes = ({ordersChoose, setOrdersChoose, firstBtn, secondBtn, thirtyBtn}) => {
    return (
        <>
            <div className={styles.ordersPopupType}>
                <div className={`${styles.ordersPopupType__item} choose`}>
                    <input checked={ordersChoose === `${firstBtn}`} onChange={() => setOrdersChoose(firstBtn)}
                           type="radio" name='historyTypeChoose'/>
                    <p className='bgBlock'>{firstBtn}</p>
                </div>
                <div className={`${styles.ordersPopupType__item} choose`}>
                    <input checked={ordersChoose === `${secondBtn}`} onChange={() => setOrdersChoose(secondBtn)}
                           type="radio" name='historyTypeChoose'/>
                    <p className='bgBlock'>{secondBtn}</p>
                </div>
                <div className={`${styles.ordersPopupType__item} choose`}>
                    <input checked={ordersChoose === `${thirtyBtn}`} onChange={() => setOrdersChoose(thirtyBtn)}
                           type="radio" name='historyTypeChoose'/>
                    <p className='bgBlock'>{thirtyBtn}</p>
                </div>
            </div>
        </>
    )
}

export default FilterThreeTypes;