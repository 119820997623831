import React from "react";
import styles from './OrderItemHistory.module.scss';
import backgroundDark from "../../../../assets/icons/bgDarkSecond.png";
import backgroundLight from "../../../../assets/icons/bgLightSecond.png";
import {useSelector} from "react-redux";
import {getText, getTheme} from "../../../../store/selectors";
import {SecondHeader} from "../../../Common/SecondHeader/SecondHeader";


const OrderItemHistory = ({selectedOrder, setSelectedOrder}) => {
    const theme = useSelector(getTheme);
    const {history} = useSelector(getText);


    return (
        <div className={selectedOrder ? styles.transactionHistoryItemActive : styles.transactionHistoryItem}
             style={theme === 'dark' ? {backgroundImage: `url(${backgroundDark})`} : {backgroundImage: `url(${backgroundLight})`}}>
            {selectedOrder && (
                <SecondHeader setShowExchange={setSelectedOrder} text={history.warrantExecuted}/>
            )}
            <div className={`${styles.transactionHistoryItem__content} bgMain`}>
                {selectedOrder && (
                    <div className={`${styles.value} bgDark`}>
                        <p>{selectedOrder.side === 'BUY' ? '+' : '-'} {selectedOrder.name}</p>
                        <span>{selectedOrder.amount}{selectedOrder.amount}</span>
                    </div>
                )}
                {selectedOrder && (
                    <div className={`${styles.status} bgBlock`}>
                        <div className={styles.status__item}>
                            <p>{history.time}</p>
                            <p className={styles.status__itemData}>
                                <span>{selectedOrder.date}</span>
                                <span>{selectedOrder.time.substring(0, 5)}</span>
                            </p>
                        </div>
                        <div className={styles.status__item}>
                            <p>{history.status}</p>
                            <p className={selectedOrder.isAccepted ? styles.status__itemGreen : styles.status__itemRed}>
                                <span>{selectedOrder.isAccepted ? 'Accepted' : 'Cancelled'}</span>
                            </p>
                        </div>
                        <div className={styles.status__item}>
                            <p>{history.side}</p>
                            <p className={selectedOrder.side === 'BUY' ? styles.status__itemGreen : styles.status__itemRed}>
                                {selectedOrder.side}
                            </p>
                        </div>
                        <div className={styles.status__item}>
                            <p>{history.type}</p>
                            <p className={styles.status__item}>
                                {selectedOrder.order}
                            </p>
                        </div>
                        <div className={styles.status__item}>
                            <p>{history.amount}</p>
                            <p className={styles.status__item}>
                                {selectedOrder.amount}
                            </p>
                        </div>
                        <div className={styles.status__item}>
                            <p>{history.ordersPrice}</p>
                            <p className={styles.status__item}>
                                {selectedOrder.order}
                            </p>
                        </div>
                    </div>
                )}
                {selectedOrder && (
                    <div className={`${styles.status} bgBlock`}>
                        <div className={styles.status__item}>
                            <p>{history.commission}</p>
                            <p>0 ? (0.00$)</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderItemHistory;