import React from "react";
import styles from './Menu.module.scss';
import arrowDown from '../../../assets/icons/arrowDown.svg';
import arrowUp from '../../../assets/icons/arrowUp.svg';
import refresh from '../../../assets/icons/refresh.svg';
import {useSelector} from "react-redux";
import {getText} from "../../../store/selectors";
import {useNavigate} from "react-router";

const Menu = () => {
    const {main} = useSelector(getText);
    const navigate = useNavigate();

    return (
        <div className={styles.menu}>
            <button onClick={() => navigate('/wallet/deposit/')}>
                <span>{main.deposit}</span>
                <img src={arrowDown} alt=""/>
            </button>
            <button onClick={() => navigate('/wallet/withdraw/')}>
                <span>{main.withdraw}</span>
                <img src={arrowUp} alt=""/>
            </button>
            <button onClick={() => navigate('/pro/exchange/')}>
                <span>{main.exchange}</span>
                <img src={refresh} alt=""/>
            </button>
        </div>
    )
}

export default Menu;