import React from "react";
import styles from './ExecutedCurrency.module.scss';
import nextIcon from '../../../../../assets/icons/next.svg'
import {getPictureSrc} from "../../../../../store/selectors";

const ExecutedCurrency = ({giveValue, getValue, giveCurrency, getCurrency}) => {
    const pictureSrc = getPictureSrc;

    return (
        <div className={`${styles.executedCurrency} bgDark`}>
            <div className={styles.icons}>
                <img className={styles.executedCurrency__icon} src={`${pictureSrc}${giveCurrency.icon}`} alt=""/>
                <div className={styles.decoration}>
                    <span className={`${styles.stroke} decoration`}/>
                    <span className={`${styles.arrow} bgSecond`}><img src={nextIcon} alt=""/></span>
                </div>
                <img className={styles.executedCurrency__icon} src={`${pictureSrc}${getCurrency.icon}`} alt=""/>
            </div>
            <div className={styles.currency}>
                <p>{giveCurrency.name}</p>
                <p>{getCurrency.name}</p>
            </div>
            <div className={styles.executedCurrency__value}>
                <span>{giveValue}</span>
                <span>{getValue}</span>
            </div>
        </div>
    )
}

export default ExecutedCurrency;