import styles from "./HistoryChoose.module.scss";
import React from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useAppSelector} from "../../../hooks/redux";
import {getText} from "../../../store/selectors";


const HistoryChoose = () => {
    const location = useLocation().pathname;
    const {history} = useAppSelector(getText);

    return (
        <div className={styles.historyChoose}>
            <div className={`${styles.historyChoose__button}`}>
                <Link className={location === '/history/orders/'
                    ? `${styles.linkActive} ${styles.active} linkActive bgBlock`
                    : `${styles.link} bgBlock`}
                      to='/history/orders/'>{history.orders}
                </Link>
            </div>
            <div className={styles.historyChoose__button}>
                <Link className={location === '/history/transactions/'
                    ? `${styles.linkActive} ${styles.active} linkActive bgBlock`
                    : `${styles.link} bgBlock`}
                      to='/history/transactions/'>{history.transactions}
                </Link>
            </div>
        </div>
    )
}

export default HistoryChoose;