import React, {useEffect, useState} from "react";
import styles from './HistoryTransactions.module.scss';
import upIcon from "../../../assets/icons/upIcon.svg";
import upIconBlue from "../../../assets/icons/upIconBlue.svg";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTheme, getUserId} from "../../../store/selectors";
import TransactionsList from "./TransactionsList/TransactionsList";
import HistoryTransactionsPopup from "./HistoryTransactionsPopup/HistoryTransactionsPopup";
import {
    getHistoryTransactionsBase,
    getHistoryTransactionsCVV,
    refreshAccessToken,
} from "../../../api/api";
import TransactionItemHistory from "./TransactionItemHistory/TransactionItemHistory";
import {Preloader} from "../../Common/Preloader/Preloader";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../../hooks/redux";
import {setAcToken} from "../../../store/account-slice";

const HistoryTransactions = ({history}) => {
    const navigate = useNavigate();
    const [transactionChoose, setTransactionsChoose] = useState('all');
    const [showTransactionPopup, setShowTransactionPopup] = useState(false);
    const [transactionsHistoryList, setTransactionsHistoryList] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const theme = useSelector(getTheme);
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [finishDate, setFinishDate] = useState('');
    const [changedToken, setChangedToken] = useState(null);

    const getTransactionsWithFilters = () => {
        if (startDate.$d && finishDate.$d && accessToken) {
            const formatStartDate = `${startDate.$d.getFullYear()}-${startDate.$d.getMonth() + 1}-${startDate.$d.getDate()}`;
            const formatEndDate = `${finishDate.$d.getFullYear()}-${finishDate.$d.getMonth() + 1}-${finishDate.$d.getDate()}`;
            const formatType = transactionChoose.toLowerCase();

            getHistoryTransactionsBase(telegramId, formatStartDate, formatEndDate, formatType, changedToken, accessToken).then((result) => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getHistoryTransactionsBase(telegramId, formatStartDate, formatEndDate, formatType, changedToken, r.access_token).then((result) => {
                                if (result && result.transactions) {
                                    setTransactionsHistoryList(result.transactions);
                                    setIsLoading(false);
                                } else {
                                    setIsLoading(false);
                                }
                            })
                        }
                    });
                }
                if (result && result.transactions) {
                    setTransactionsHistoryList(result.transactions);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        }
    }

    const onGetCVV = () => {
        const formatStartDate = `${startDate.$d.getFullYear()}-${startDate.$d.getMonth() + 1}-${startDate.$d.getDate()}`;
        const formatEndDate = `${finishDate.$d.getFullYear()}-${finishDate.$d.getMonth() + 1}-${finishDate.$d.getDate()}`;

        getHistoryTransactionsCVV(telegramId, formatStartDate, formatEndDate, transactionChoose, changedToken, accessToken).then(result => {
            if (result && result.detail && result.detail === 'access_token expired') {
                refreshAccessToken(refreshToken).then(r => {
                    if (r && r.access_token) {
                        dispatch(setAcToken(r.access_token));
                        getHistoryTransactionsCVV(telegramId, formatStartDate, formatEndDate, transactionChoose, changedToken, r.access_token).then(result => {
                            if (result && result.file_path) {
                                navigate(`${result.file_path}`);
                            }
                        })
                    }
                });
            }

            if (result && result.file_path) {
                navigate(`${result.file_path}`);
            }
        })
    }

    useEffect(() => {
        getTransactionsWithFilters();
    }, [startDate, finishDate, transactionChoose, changedToken]);

    useEffect(() => {
        if (telegramId) {
            getTransactionsWithFilters();
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false);
            }, 2500)
        }
    }, [telegramId])


    return (
        <div className={styles.historyTransactions}>
            {/*<TransactionsChoose transactionChoose={transactionChoose} setTransactionsChoose={setTransactionsChoose}/>*/}
            <button onClick={() => setShowTransactionPopup(true)} className={`${styles.filter} bgBlock`}>
                <span>{history.chooseCurrency}</span>
                <img src={theme === 'dark' ? upIcon : upIconBlue} alt=""/>
            </button>
            <TransactionsList transactionsHistoryList={transactionsHistoryList}
                              setSelectedTransaction={setSelectedTransaction}/>
            <HistoryTransactionsPopup showTransactionPopup={showTransactionPopup}
                                      setShowTransactionPopup={setShowTransactionPopup}
                                      transactionChoose={transactionChoose}
                                      startDate={startDate} setStartDate={setStartDate} finishDate={finishDate}
                                      setTransactionsChoose={setTransactionsChoose}
                                      setFinishDate={setFinishDate} changedToken={changedToken}
                                      setChangedToken={setChangedToken} onGetCVV={onGetCVV}/>
            <TransactionItemHistory selectedTransaction={selectedTransaction}
                                    setSelectedTransaction={setSelectedTransaction}/>
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default HistoryTransactions;