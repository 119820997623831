import React, {useEffect, useState} from "react";
import styles from './ExchangeContent.module.scss';
import changeIcon from '../../../assets/icons/change.svg';
import changeIconWhite from '../../../assets/icons/changeWhite.svg';
import Changer from "./Changer/Changer";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getText, getTheme, getUserId, getWallets} from "../../../store/selectors";
import ExchangeExecuted from "./ExchangeExecuted/ExchangeExecuted";
import {getAvailableTokens, refreshAccessToken, removeOpenOrders, sendSwap, setTwoFactorCode} from "../../../api/api";
import {Preloader} from "../../Common/Preloader/Preloader";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {setAcToken, setTwoFactorAuth} from "../../../store/account-slice";
import {removeOrder} from "../../../store/pro-slice";

const ExchangeContent = () => {
    const wallets = useSelector(getWallets);
    const {pro} = useSelector(getText);
    const theme = useSelector(getTheme);
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const [showExchange, setShowExchange] = useState(false);
    const [giveCurrency, setGiveCurrency] = useState('');
    const [giveValue, setGiveValue] = useState(0.00);
    const [getCurrency, setGetCurrency] = useState('');
    const [getValue, setGetValue] = useState(0.00);
    const [getTokens, setGetTokens] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [commission, setCommission] = useState(null)

    useEffect(() => {
        const token = wallets.filter(item => item.name === 'BTC');
        setGiveCurrency(...token);
        setMainToken(...token);
    }, [wallets])


    const setMainToken = (value) => {
        setGiveCurrency(value);
        setTimeout(() => {
            if (value && value.name) {
                setIsLoading(true);
                if (getCurrency !== '') {
                    setGetCurrency(null);
                }
                setGiveValue(0.00);
                setGetValue(0.00);
                getAvailableTokens(value.name, accessToken).then(response => {
                    if (response && response.detail && response.detail === 'access_token expired') {
                        refreshAccessToken(refreshToken).then(r => {
                            if (r && r.access_token) {
                                dispatch(setAcToken(r.access_token));
                                getAvailableTokens(value.name, r.access_token).then(response => {
                                    setIsLoading(false);
                                    setGetTokens(response);
                                    if (getCurrency === '') {
                                        const newGetItem = response.filter(item => item.name === 'USDT');
                                        setGetCurrency(...newGetItem);
                                        setIsLoading(false);
                                    }
                                })
                            }
                        });
                    } else {
                        setIsLoading(false);
                        setGetTokens(response);
                        if (getCurrency === '') {
                            const newGetItem = response.filter(item => item.name === 'USDT');
                            setGetCurrency(...newGetItem);
                            setIsLoading(false);
                        }
                    }

                })
            }
        }, 500)
    }

    const changeTokens = () => {
        setIsLoading(true);
        const giveCurrencyName = giveCurrency.name
        const filerArray = wallets.filter(item => item.name === getCurrency.name);
        const newGiveItem = filerArray[0];
        setGiveValue(0.00);
        setGetValue(0.00);
        setGiveCurrency(newGiveItem);
        if (newGiveItem && newGiveItem.name) {
            getAvailableTokens(newGiveItem.name, accessToken).then(response => {
                if (response && response.detail && response.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getAvailableTokens(newGiveItem.name, r.access_token).then(response => {
                                if (response) {
                                    setGetTokens(response);
                                    const newGetItem = response.filter(item => item.name === giveCurrencyName);
                                    setGetCurrency(newGetItem[0]);
                                    setIsLoading(false);
                                }
                            })
                        }
                    });
                } else {
                    if (response) {
                        setGetTokens(response);
                        const newGetItem = response.filter(item => item.name === giveCurrencyName);
                        setGetCurrency(newGetItem[0]);
                        setIsLoading(false);
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (getCurrency && getCurrency.ticker) {
            getCurrency.name === getCurrency.ticker.substring(0, getCurrency.name.length)
                ? setGetValue(giveValue / getCurrency.price)
                : setGetValue(giveValue * getCurrency.price)
        }
    }, [getCurrency]);


    const onExchange = () => {
        setIsLoading(true);
        if (telegramId && accessToken) {
            sendSwap(telegramId, giveCurrency.name, getCurrency.name, giveValue, accessToken).then(response => {
                if (response && response.detail && response.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            sendSwap(telegramId, giveCurrency.name, getCurrency.name, giveValue, r.access_token).then(response => {
                                if (response && response.commission && response.transactTime) {
                                    setCommission(response.commission)
                                    setShowExchange(true);
                                    setIsLoading(false);
                                }
                            })
                        }
                    });
                }
                if (response && response.commission && response.transactTime) {
                    setCommission(response.commission)
                    setShowExchange(true);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        } else {
            setIsLoading(false);
        }
    }

    return (
        <div className={styles.exchangeContent}>
            <div className={styles.changeContainer}>
                <Changer
                    currentCurrency={giveCurrency}
                    value={giveValue}
                    setValue={setGiveValue}
                    setSecondValue={setGetValue}
                    operation={'Give'}
                    setCurrency={setMainToken}
                    currency={wallets}
                    price={getCurrency}
                    giveValue={giveValue}
                    setIsLoading={setIsLoading}
                    text={pro}
                />
                {getCurrency && (
                    <button className={styles.changeIcon} onClick={() => changeTokens()}>
                        <img src={theme === 'dark' ? changeIcon : changeIconWhite} alt=""/>
                    </button>
                )}
                {getTokens && (
                    <Changer
                        currentCurrency={getCurrency}
                        value={getValue}
                        setValue={setGetValue}
                        setSecondValue={setGiveValue}
                        operation={'Get'}
                        setCurrency={setGetCurrency}
                        currency={getTokens}
                        price={getCurrency}
                        giveValue={giveValue}
                        setIsLoading={setIsLoading}
                        text={pro}
                    />
                )}
            </div>
            {giveCurrency && getCurrency && (
                <p className={styles.changeValue}>
                    <span>1 {giveCurrency.name}</span> ≈ {
                    getCurrency.name === getCurrency.ticker.substring(0, getCurrency.name.length) ? `${(1 / getCurrency.price).toFixed(6)}` : `${(1 * getCurrency.price).toFixed(6)}`
                } <span>{getCurrency.name}</span>
                </p>
            )}

            <button onClick={() => onExchange()} className={`${styles.btnExchange} action`} disabled={giveValue == 0}>
                {pro.exchange}
            </button>
            {giveCurrency && getCurrency && (
                <ExchangeExecuted
                    giveValue={giveValue}
                    getValue={getValue}
                    text={pro}
                    showExchange={showExchange}
                    setShowExchange={setShowExchange}
                    giveCurrency={giveCurrency}
                    getCurrency={getCurrency}
                    commission={commission}
                />
            )}
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}

        </div>
    )
}

export default ExchangeContent;