import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    telegramId: null,
    theme: 'dark',
    isAuthTwoFactor: false,
    refToken: null,
    acToken: null,
};

export const accountSlice = createSlice({
    name: 'accountPage',
    initialState,
    reducers: {
        setStateTheme(state, {payload}) {
            state.theme = payload;
        },
        setTelegramId(state, {payload}) {
            state.telegramId = payload;
        },
        setRefToken(state, {payload}) {
            state.refToken = payload;
        },
        setAcToken(state, {payload}) {
            state.acToken = payload;
        },
        setTwoFactorAuth(state, {payload}) {
            state.isAuthTwoFactor = payload;
        },
    }
})

export default accountSlice.reducer;

export const {
    setStateTheme,
    setTelegramId,
    setTwoFactorAuth,
    setRefToken,
    setAcToken
} = accountSlice.actions;