import React, {useState} from "react";
import styles from './NetworkPopup.module.scss';
import {ClosePopup} from "../../../Common/ClosePopup/ClosePopup";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../../../hooks/redux";
import {setCurrentCurrency, setCurrentNetwork} from "../../../../store/wallet-slice";

const NetworkPopup = ({showAddNetwork, networks, setShowAddNetwork, networkTitle, tokenName}) => {
    const [network, setNetwork] = useState(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onSubmit = (networkSelected, name) => {
        setNetwork(networkSelected);
        dispatch(setCurrentNetwork(networkSelected));
        dispatch(setCurrentCurrency(name));
        setTimeout(() => {
            navigate('/wallet/create/')
        }, 250)
    }

    return (
        <div className={showAddNetwork ? `${styles.networkPopupActive} bgSecond` : `${styles.networkPopup} bgSecond`}>
            <ClosePopup setShowPopup={setShowAddNetwork} />
            <p className={styles.title}>{networkTitle}</p>
            <ul className={styles.networks}>
                {networks.map((network, index) => (
                    <li key={index} className='switch'>
                        <input type="radio" name='networkWallet' onChange={() => onSubmit(network.networkType, tokenName)} />
                        <div className={styles.networkChange}>
                            <span>{network.networkType}</span>
                            <span className='circle' />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default NetworkPopup;