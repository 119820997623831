import React from "react";
import styles from './TransactionItemHistory.module.scss';
import {useSelector} from "react-redux";
import {getText, getTheme} from "../../../../store/selectors";
import backgroundDark from "../../../../assets/icons/bgDarkSecond.png";
import backgroundLight from "../../../../assets/icons/bgLightSecond.png";
import {SecondHeader} from "../../../Common/SecondHeader/SecondHeader";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copy from "../../../../assets/icons/copyGrey.svg";
import {useAppDispatch} from "../../../../hooks/redux";
import {setEventPopup} from "../../../../store/main-slice";

const TransactionItemHistory = ({selectedTransaction, setSelectedTransaction}) => {
    const theme = useSelector(getTheme);
    const {wallet} = useSelector(getText);
    const dispatch = useAppDispatch();

    const onCopy = (e) => {
        e.stopPropagation();
        dispatch(setEventPopup({
            success: true,
            text: 'Copied successfully!'
        }))
    }

    return (
        <div className={selectedTransaction ? styles.orderItemHistoryActive : styles.orderItemHistory}
             style={theme === 'dark' ? {backgroundImage: `url(${backgroundDark})`} : {backgroundImage: `url(${backgroundLight})`}}>
            {selectedTransaction && (
                <SecondHeader setShowExchange={setSelectedTransaction} text={`${selectedTransaction.token} ${selectedTransaction.network.toUpperCase()}`}/>
            )}
            <div className={`${styles.orderItemHistory__content} bgMain`}>
                {selectedTransaction && (
                    <div className={`${styles.value} bgDark`}>
                        <p>{selectedTransaction.isRecieve ? '+' : '-'} {selectedTransaction.amount}</p>
                        {/*<span>=192$</span>*/}
                    </div>
                )}
                {selectedTransaction && (
                    <div className={`${styles.status} bgBlock`}>
                        <div className={styles.status__item}>
                            <p>{wallet.time}</p>
                            <p className={styles.status__itemData}>
                                <span>{selectedTransaction.date}</span>
                                <span>{selectedTransaction.time.substring(0, 5)}</span>
                            </p>
                        </div>
                        <div className={styles.status__item}>
                            <p>{wallet.wallet}</p>
                            <p className={styles.status__item}>
                                {`${selectedTransaction.wallet_address.substring(0, 5)}.....${selectedTransaction.wallet_address.substring(selectedTransaction.wallet_address.length - 5, selectedTransaction.wallet_address.length)}`}
                            </p>
                        </div>
                    </div>
                )}
                {selectedTransaction && (
                    <div className={`${styles.status} bgBlock`}>
                        <div className={styles.status__item}>
                            <p>{wallet.commission}</p>
                            <p>0 ? (0.00$)</p>
                        </div>
                    </div>
                )}
                {selectedTransaction && (
                    <div className={`${styles.processingId} bgBlock`}>
                        <div className={styles.processingId__address}>
                            <span>TxID</span>
                            <p>{`${selectedTransaction.hash.substring(0, 10)}.....${selectedTransaction.hash.substring(selectedTransaction.hash.length - 10, selectedTransaction.hash.length)}`}</p>
                        </div>
                        <CopyToClipboard text={selectedTransaction.hash}>
                            <button className='bgLittleBtn' type='button' onClick={(e) => onCopy(e)}>
                                <img src={copy} alt="copy"/>
                            </button>
                        </CopyToClipboard>
                    </div>
                )}
                <button className={`${styles.action} cancelBtn`}>
                    <span>{wallet.viewExplorer}</span>
                </button>
            </div>
        </div>
    )
}

export default TransactionItemHistory;