import React from "react";
import styles from './FilterCouple.module.scss';
import favoriteIcon from '../../../../../assets/icons/favorite.svg';
import favoriteBlueIcon from '../../../../../assets/icons/favoriteBlue.svg';
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTheme, getUserId} from "../../../../../store/selectors";
import {getTickersFavorite, getTickersList, getTickersToken, refreshAccessToken} from "../../../../../api/api";
import {useAppDispatch} from "../../../../../hooks/redux";
import {setTickets} from "../../../../../store/pro-slice";
import {setAcToken} from "../../../../../store/account-slice";

const FilterCouple = ({tokenFilter, setTokenFilter, setIsLoading}) => {
    const theme = useSelector(getTheme);
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const tokens = [
        {token: 'favorite'},
        {token: 'USDC'},
        {token: 'ETH'},
        {token: 'BTC'},
        {token: 'BNB'},
        {token: 'LTC'},
        {token: 'TRX'},
        {token: 'USDT'},
    ]

    const onSubmit = (token) => {
        if (token === tokenFilter && telegramId && accessToken) {
            setIsLoading(true);
            setTokenFilter('');

            getTickersList(telegramId, accessToken).then((r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getTickersList(telegramId, r.access_token).then((r => {
                                if (r) {
                                    dispatch(setTickets(r));
                                    setIsLoading(false);
                                }
                            }));
                        }
                    });
                }
                if (r) {
                    dispatch(setTickets(r));
                    setIsLoading(false);
                }
            }))
        }
    }

    const onFilter = (token) => {
        setIsLoading(true);
        if (token === 'favorite' && telegramId && accessToken) {
            setTokenFilter(token);
            getTickersFavorite(telegramId, true, accessToken).then(r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getTickersFavorite(telegramId, true, r.access_token).then(r => {
                                dispatch(setTickets(r));
                                setIsLoading(false);
                            })
                        }
                    });
                }
                dispatch(setTickets(r));
                setIsLoading(false);
            })
        } else if (token && telegramId && accessToken) {
            setTokenFilter(token);
            getTickersToken(telegramId, token, accessToken).then(r => {
                if (r && r.detail && r.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getTickersToken(telegramId, token, r.access_token).then(r => {
                                dispatch(setTickets(r));
                                setIsLoading(false);
                            })
                        }
                    });
                }
                dispatch(setTickets(r));
                setIsLoading(false);
            });
        } else  {
            setIsLoading(false);
        }
    }

    return (
        <div className={styles.filterCouple}>
            {/*<div className={theme === 'dark' ? showFavorites ? `${styles.filterCouple__favoriteActive} action` : styles.filterCouple__favorite*/}
            {/*    : showFavorites ? `${styles.filterCouple__favoriteActive} action` : styles.filterCouple__favoriteLight}>*/}
            {/*    <button onClick={() => setShowFavorites(!showFavorites)}>*/}
            {/*        <img src={theme === 'light' && !showFavorites ? favoriteBlueIcon : favoriteIcon} alt=""/>*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className={styles.couplesWrapper}>
                {tokens && tokens.length && (
                    tokens.map((item, index) => (
                        <div key={index}
                             className={item.token === 'favorite' ? `${styles.couples} ${styles.favorite} choose bgBlock` : `${styles.couples} choose bgBlock`}>
                            <input type="radio" name='couples' onChange={() => onFilter(item.token)}
                                   checked={tokenFilter === item.token} onClick={() => onSubmit(item.token)}/>
                            <p>
                                {item.token === 'favorite'
                                    ? <img src={theme === 'light' && !tokenFilter ? favoriteBlueIcon : favoriteIcon}
                                           alt=""/>
                                    : <span>{item.token}</span>}

                            </p>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default FilterCouple;