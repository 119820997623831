import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    language: 'english',
    text: {
        main: {
            totalAmount: 'Total amount in wallets',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            exchange: 'Exchange',
        },
        wallet: {
            title: 'Wallets',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            exchange: 'Exchange',
            all: 'All',
            hideZero: 'Hide zero balance',
            commercial: 'Commercial',
            warrants: 'In warrants',
            finalBalanas: 'Final balance',
            addAddress: 'Add address',
            selectNetwork: 'Select network',
            selectWithdraw: 'Select asset to withdraw',
            selectDeposit: 'Select asset to deposit',
            requestAddress: 'Request new address',
            sendTetherPart1: 'Send only',
            sendTetherPart2: 'on the',
            sendTetherPart3: 'network  to this address',
            changeWallet: 'Change wallet',
            insertAddress: 'Insert address',
            generateAddress: 'Generate new address',
            addWallet: 'Add a wallet',
            createWallet: 'Create a wallet',
            nameWallet: 'Name the wallet',
            nameForWallet: 'Name for the wallet',
            newWalletAddress: 'New wallet address',
            save: 'Save',
            amountIn: 'Amount in',
            fee: 'Fee',
            minimum: 'Minimum',
            maximum: 'Maximum withdrawal',
            previewWithdrawal: 'Preview withdrawal',
            withdrawWalletInfoPart1: 'Only withdraw',
            to: 'to',
            withdrawWalletInfoPart2: 'addresses.Withdrawals to addresses generated for other networks could result in loss of funds',
            managerDeposit: 'Manager deposit addresses',
            authenticatorApp: 'Authenticator app',
            support: 'Contact Support',
            codeText: 'Enter the Sign-in 2FA code from your authenticator app',
            time: 'Time',
            status: 'Status',
            wallet: 'Wallet',
            commission: 'Commission',
            viewExplorer: 'View on block explorer',
        },
        pro: {
            headerTitle: 'Exchange',
            title: 'Swap',
            warrantExecuted: 'Warrant is executed',
            day: 'Day',
            status: 'Status',
            commission: 'Commission',
            today: 'Today',
            noFavorites: 'No favorite  currencies yet',
            spot: 'Spot',
            swap: 'Swap',
            market: 'Market',
            limit: 'Limit',
            limitPrice: 'Limit price',
            marketPrice: 'Market price',
            buy: 'Buy',
            sell: 'Sell',
            quanity: 'Quanity',
            total: 'Total',
            availableBalance: 'Available balance',
            openOrders: 'Open orders',
            noOpenOrders: 'No open orders',
            exchange: 'Exchange',
        },
        manager: {
            title: 'Manager',
        },
        history: {
            title: 'История',
            filter: 'Фильтр',
            chooseCurrency: 'Выберите валюту',
            warrantExecuted: 'Ордер исполнен',
            time: 'Время',
            status: 'Статус',
            side: 'Сторона',
            type: 'Тип',
            amount: 'Количество',
            ordersPrice: 'Стоимость заказов',
            commission: 'Комиссия',
            orders: 'Заказы',
            transactions: 'Транзакции',
            all: 'Все',
            limit: 'Лимит',
            market: 'Рынок',
            oneDay: '24ч',
            sevenDay: 'Последние 7д',
            send: 'Получено',
            receive: 'Отправлено',
            done: 'Применить',
            getCvv: 'Получить CVV',
        },
        account: {
            title: 'Account',
            safety: 'Safety',
            verification: 'Two factor verification',
            KycVerification: 'KYC verification',
            KycVerificationText: 'To get full access to the bots functions, please complete your identity verification process.',
            aml: 'AML',
            information: 'Information',
            support: 'Support',
            language: 'Language',
            history: 'History CVV',
            downloadText: 'Download and install the Google app Authenticator',
            downloadLink: 'Download the app',
            enter: 'Enter',
            sendAuthCode: 'Google Authenticator Verification Code',
            code6: '6-digit code',
            historyCVV: 'History CVV',
            type: 'Type',
            period: 'Period',
            currencyPair: 'Currency pair',
            get: 'Get',
            currency: 'Currency',
            telegramBot: 'Protects your account and transactions, works through Telegram Bot @S WalletBot',
            googleAuth: 'Protects your account  and transactions',
            googleAuthOff: 'Disabling two-factor authentication (2FA) exposes your account to a high risk  of unauthorized access. If you decide  to disable (2FA), we strongly recommend that you enable it again as soon as possible.  If unusual activity is detected after  the shutdown (2FA) , you will not be able  to withdraw funds or sell assets on the P2P platform for 24 hours.',
            getContinue: 'I get it, continue',
            english: 'English',
            russian: 'Russian',
        },
        global: {
            main: 'Main',
            wallet: 'Wallet',
            pro: 'Pro',
            manager: 'Manager',
            history: 'History',
            cancel: 'Cancel',
        },
        aml: {
            title: 'AML verification',
            amlPayment: 'Payment for inspection',
            amlAddress: 'Address verification ',
            token: 'Token',
            commission: 'The amount of the check payment:',
            pay: 'Pay',
            enterAddress: 'Enter the token of the address you want to check',
            network: 'Network',
            send: 'Send for inspection',
            address: 'Address',
            riskLow: 'Low risk address',
            riskMedium: 'medium risk address',
            riskHigh: 'High risk address',
            toHome: 'To home page',
            other: 'Other',
            paymentManagement: 'Payment Management',
            wallet: 'Wallet',
            exchange: 'Exchange',
            liquidityPools: 'Liquidity Pools',
            exchangeHigh: 'Exchange | High Risk',
            highP2P: 'High-Risk P2P Exchange',
            gambling: 'Gambling',
            sanctions: 'Sanctions',
            stolenCoins: 'Stolen Coins',
            transactions: 'Transactions:',
            modeChecked: 'mode. Checked:',
            lowRisk: 'Low risk',
            mediumRisk: 'Medium risk',
            highRisk: 'High risk',
        }
    }
};

export const languageSlice = createSlice({
    name: 'languagePage',
    initialState,
    reducers: {
        setText(state, {payload}) {
            state.text = payload;
        },
        setLanguage(state, {payload}) {
            state.language = payload;
        }
    }
})

export default languageSlice.reducer;

export const {
    setText,
    setLanguage,
} = languageSlice.actions;