import React from "react";
import styles from "./TransactionsCurrency.module.scss";


const TransactionsCurrency = ({currencyFilter, setCurrencyFilter}) => {

    return (
        <div className={styles.transactionTypeCVV}>
            <div className={styles.transactionTypeCVV__list}>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input checked={currencyFilter === 'all'} onChange={() => setCurrencyFilter('all')}
                           type="radio" name='currencyFilter' />
                    <span className='bgBlock'>All</span>
                </div>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input checked={currencyFilter === 'crypto'} onChange={() => setCurrencyFilter('crypto')}
                           type="radio" name='currencyFilter' />
                    <span className='bgBlock'>Crypto</span>
                </div>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input checked={currencyFilter === 'fiat'} onChange={() => setCurrencyFilter('fiat')}
                           type="radio" name='currencyFilter' />
                    <span className='bgBlock'>Fiat</span>
                </div>
            </div>
        </div>
    )
}

export default TransactionsCurrency;