import React from "react";
import styles from './ManagerCryptoList.module.scss';
import {getPictureSrc} from "../../../store/selectors";
import {Link} from "react-router-dom";

const ManagerCryptoList = ({list}) => {
    const pictureSrc = getPictureSrc;

    return (
        <ul className={styles.managerCryptoList}>
            {list && (
                list.map((item, index) => (
                    <li key={index}>
                        <Link to={'/wallet/' + item.id} className={`${styles.item} bgBlock`}>
                            <div className={styles.item__name}>
                                <img src={`${pictureSrc}${item.icon}`} alt=""/>
                                <p>{item.name}</p>
                            </div>
                            <div className={styles.item__value}>
                                <p>{item.finalBalance} {item.name}</p>
                            </div>
                        </Link>
                    </li>
                ))
            )}
        </ul>
    )
}

export default ManagerCryptoList;